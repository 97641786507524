import { AxiosResponse } from "axios";
import { IActor } from "../models/IActor";
import axios from "axios";
import { env } from "../config";
import FilterStore from "../store/FilterStore";

const $api = axios.create({
  baseURL: env.api,
});

export default class ActorService {
  static async fetchActors(
    page: number,
    limit: number,
    filter?: FilterStore
  ): Promise<
    AxiosResponse<{ actors: IActor[]; count: number; pages: number }>
  > {
    const str = [page, limit].join("/");
    return $api.post<{ actors: IActor[]; count: number; pages: number }>(
      `/actors/${str}`,
      { filter }
    );
  }

  static async fetchActor(id: string): Promise<AxiosResponse<IActor>> {
    return $api.get<IActor>(`/actor/${id}`);
  }
}
