export const env = {
    mail: 'ainman@mail.ru',
    mail2: 'askarfilm@gmail.com',
    localhost: "http://localhost:3001/",
    localhost_prod: "http://cinemacast.ru/",
    // api: "http://localhost:3002/api",
    // url: "http://localhost:3002",
    api: "http://cinemacast.ru/api",
    url: "http://89.108.79.27:3002",
    api_prodaction: `http://89.108.79.27:3002/api`,
    url_prodaction: 'http://89.108.79.27:3002'
}