import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { Container, Form, InputGroup, Row, Col, Button } from "react-bootstrap";
import { Context } from "../index";
import CustomMultiRange from "./CustomMultiRange";
import { constitution, eyeColor, hairColor, race } from "../helpers/types";

interface Props {
  doFiltrate?: () => void;
}

const CustomFilter = ({ doFiltrate }: Props) => {
  const { filterStore, actorStore } = useContext(Context);
 
  const radio = (value: number, label: string) => {
    return (
      <Form.Check
        inline
        style={{ cursor: "pointer", margin: "auto" }}
        name={`gender-${value}`}
        type="radio"
        id={`radio-${value}`}
        label={label}
        value={value}
        onChange={(val) => (filterStore.gender = parseInt(val.target.value))}
        checked={filterStore.gender === value}
      />
    );
  };

  return (
    <Form >
      <div className="d-lg-none d-xs-block">
        <Form.Control
          placeholder="Введите ФИО актера"
          type="text"
          value={filterStore.fio}
          onInput={(val) => (filterStore.fio = val.currentTarget.value)}
        />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Form.Group>
          <Form.Text>Пол</Form.Text>
          <br />
          <div style={{ display: "flex" }}>
            {radio(0, "любой")}
            {radio(1, "М")}
            {radio(2, "Ж")}
          </div>
        </Form.Group>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <CustomMultiRange
          label="Возраст"
          min={0}
          max={130}
          minValue={filterStore.minAge}
          maxValue={filterStore.maxAge}
          setMin={(age: number) => filterStore.setMinAge(age)}
          setMax={(age: number) => filterStore.setMaxAge(age)}
        />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <CustomMultiRange
          label="Рост"
          min={0}
          max={300}
          minValue={filterStore.minHeight}
          maxValue={filterStore.maxHeight}
          setMin={(age: number) => filterStore.setMinHeight(age)}
          setMax={(age: number) => filterStore.setMaxHeight(age)}
        />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <CustomMultiRange
          label="Вес"
          min={0}
          max={300}
          minValue={filterStore.minWeight}
          maxValue={filterStore.maxWeight}
          setMin={(age: number) => filterStore.setMinWeight(age)}
          setMax={(age: number) => filterStore.setMaxWeight(age)}
        />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Form.Text>Тип внешности</Form.Text>
        <Form.Select
          value={filterStore.race}
          onChange={(val) => (filterStore.race = Number(val.target.value))}
        >
          <option value="DEFAULT" disabled>
            Выберете тип внешности
          </option>
          {race.map((item, idx) => {
            return (
              <option key={idx} value={item.id}>
                {item.text}
              </option>
            );
          })}
        </Form.Select>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Form.Text>Тип телосложения</Form.Text>
        <Form.Select
          value={filterStore.constitution}
          onChange={(val) =>
            (filterStore.constitution = Number(val.target.value))
          }
        >
          <option value="DEFAULT" disabled>
            Выберете тип внешности
          </option>
          {constitution.map((item, idx) => {
            return (
              <option key={idx} value={item.id}>
                {item.text}
              </option>
            );
          })}
        </Form.Select>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Form.Text>Цвет глаз</Form.Text>
        <Form.Select
          value={filterStore.eyeColor}
          onChange={(val) => (filterStore.eyeColor = Number(val.target.value))}
        >
          <option value="DEFAULT" disabled>
            Выберете цвет глаз
          </option>
          {eyeColor.map((item, idx) => {
            return (
              <option key={idx} value={item.id}>
                {item.text}
              </option>
            );
          })}
        </Form.Select>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Form.Text>Цвет волос</Form.Text>
        <Form.Select
          value={filterStore.hairColor}
          onChange={(val) => (filterStore.hairColor = Number(val.target.value))}
        >
          <option value="DEFAULT" disabled>
            Выберете цвет волос
          </option>
          {hairColor.map((item, idx) => {
            return (
              <option key={idx} value={item.id}>
                {item.text}
              </option>
            );
          })}
        </Form.Select>
      </div>
      <div className="d-grid">
        <br />
        <Button
          style={{
            fontSize: 14,
            width: "100%",
          }}
          variant="primary"
          onClick={doFiltrate}
        >
          Поиск
        </Button>
        <br />
        <Button
          style={{
            fontSize: 14,
            width: "100%",
          }}
          variant="secondary"
          onClick={() => filterStore.clear()}
        >
          Очистить
        </Button>
      </div>

      <div></div>
    </Form>
  );
};

export default observer(CustomFilter);
