import React, { useContext, useEffect } from "react"
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { observer } from "mobx-react-lite"
import CustomPhotoSlider from "../../components/CustomItemSlider/CustomPhotoSlider/CustomPhotoSlider"
import CustomVideoSlider from "../../components/CustomItemSlider/CustomVideoSlider/CustomVideoSlider"
import Skills from "../../components/Skills"
import ActorMainAvatar from "./components/ActorMainAvatar"

import { Context } from '../../index'


import { typeToGetMapedData, getMapedData, declOfNum, Film } from '../../helpers/types'
import CustomCard from '../../components/CustomCard';

interface Props {
    id?: string,
    showFullname?: boolean,
    socialMediaColSize?: number,
    avatarHeight?: string
}
const Actor = ({ id, showFullname = false, socialMediaColSize, avatarHeight }: Props) => {

    const { actorStore } = useContext(Context)

    useEffect(() => {
        actorStore.setLoading(true)
        setTimeout(() => {
            if (id) {
                actorStore
                    .getActor(id)
                    .then(() => document.title = actorStore.getFullName())
            }
        }, 500)
    }, [id])

    if (actorStore.isLoading) {
        return (
            <Container style={{
                textAlign: 'center',
                marginTop: '25%',
                height: '200px'
            }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        )
    }

    const rowSpecial = (label: string, info: any) => {
        return (
            <Row style={{ borderBottom: '2px solid aliceblue', marginBottom: 15, marginLeft: 5, marginRight: 5, paddingBottom: 5, }}>
                <Col md={3} xs={12}>
                    <span style={{ color: '#9aa8b6' }}>{label}</span>
                </Col>
                <Col md={9} xs={12}>
                    <span className="d-flex flex-md-row-reverse" style={{ opacity: 0.75 }}>
                        {info ? info : 'Неизвестно'}
                    </span>
                </Col>
            </Row>
        )
    }

    const renderFilmgraphy = (filmography?: Film[]) => {
        if (filmography && filmography.length > 0) {
            return (
                <div>
                    <ul >
                        {filmography?.map(({ name, character }, index) => {
                            return (
                                <li key={index} style={{ marginLeft: '-14px' }}>
                                    {name ?
                                        <p>
                                            <span>
                                                {name}
                                            </span>
                                            {character ? <span style={{ color: '#9FAAB2' }}> - {character}</span>
                                                : ''
                                            }
                                        </p>
                                        : ''
                                    }
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )
        }

    }

    return (
        <>
            <CustomCard label={showFullname ? actorStore.getFullName() : ''}>
                <Row >
                    <Col md={4} xs={12}>
                        <ActorMainAvatar actor={actorStore.actor} socialMediaColSize={socialMediaColSize} avatarHeight={avatarHeight} />
                    </Col>
                    <Col md={8} xs={12}>
                        <Row>
                            <Col md={12} xs={12}>
                                <br />
                                {rowSpecial('Возраст', actorStore.actor.age ? `${actorStore.actor.age} ${declOfNum(actorStore.actor.age)}` : undefined)}
                                {rowSpecial('Гражданство', getMapedData(typeToGetMapedData.citizenship, actorStore.actor.citizenship))}
                                {rowSpecial('Тип внешности', getMapedData(typeToGetMapedData.race, actorStore.actor.race))}
                                {rowSpecial('Образование', getMapedData(typeToGetMapedData.education, actorStore.actor.education))}
                                {rowSpecial('Рост', actorStore.actor.height)}
                                {rowSpecial('Вес', actorStore.actor.weight)}
                                {rowSpecial('Цвет волос', getMapedData(typeToGetMapedData.hairColor, actorStore.actor.hairColor))}
                                {rowSpecial('Цвет глаз', getMapedData(typeToGetMapedData.eyeColor, actorStore.actor.eyeColor))}
                                <br />
                                <Row>
                                    <Col md={7} xs={12}>
                                        <CustomPhotoSlider items={actorStore.actor.photos ?? []} />

                                    </Col>
                                    <Col md={5} xs={12}>
                                        <CustomVideoSlider items={actorStore.actor.videos ?? []} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </CustomCard>
            <Row>
                <Col md={12} xs={12}>
                    <Row>
                        <Col md={7} xs={12}>
                            <br />
                            <CustomCard label="Навыки">
                                {actorStore.actor.skills?.length ?
                                    <div style={{ padding: 5 }}>
                                        <Row md={12}>
                                            <Skills items={actorStore.actor.skills} />
                                        </Row>
                                    </div>
                                    : undefined
                                }
                            </CustomCard>
                        </Col>

                        <Col md={5} xs={12}>
                            <br />
                            <CustomCard label="Фильмография">
                                {renderFilmgraphy(actorStore.actor.filmography)}
                            </CustomCard>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br />

        </>
    )
}

export default observer(Actor)