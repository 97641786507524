import React  from "react"
import ActorSimpleRow from "./ActorSimpleRow"
import ActorMoreInfoRow from "./ActorMoreInfoRow"
import ActorPhotosRow from "./ActorPhotosRow"
import { IActor } from '../../../models/IActor'
import InfiniteScroll from "react-infinite-scroll-component"
import { Container, Row, Spinner } from "react-bootstrap"

interface Props {
    fetchMoreData: () => Promise<void>
    actors: IActor[]
    colSize: number
    count: number
}


const ActorsInfiniteScroll = ({ fetchMoreData, actors = [] as IActor[], colSize, count }: Props) => {

    const actorRow = (actor: IActor) => {
        switch (colSize) {
            case 1:
                return <ActorSimpleRow key={actor._id} actor={actor} />
            case 2:
                return <ActorMoreInfoRow key={actor._id} actor={actor} />
            case 3:
                return <ActorPhotosRow key={actor._id} actor={actor} />
            default:
                return <ActorSimpleRow key={actor._id} actor={actor} />
        }
    }


    const loadProcess = () => {
        return (
            <Container style={{
                textAlign: 'center',
                marginTop: '25%',
                height: '200px'
            }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        )
    }
    return (
        <InfiniteScroll
            next={fetchMoreData}
            loader={loadProcess()}
            dataLength={actors.length}
            hasMore={actors.length < count}
        >
            <Container>
                <Row>
                    {actors.map(actor => actorRow(actor))}
                </Row>
            </Container>
        </InfiniteScroll>
    )

}

export default ActorsInfiniteScroll