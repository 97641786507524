import { makeAutoObservable } from "mobx"
import ActorService from "../services/ActorService"
import { IActor } from '../models/IActor'
import FilterStore from './FilterStore';
export default class ActorStore {

    isLoading = false
    isLoadingPhotos = false


    allActors = [] as IActor[]
    actors = [] as IActor[]

    count = 0
    pages = 0

    actor = {
        patronymic: '',
        firstName: '',
        lastName: '',
        gender: 0,
        birthDay: '',
        age: undefined,
        race: 0,
        citizenship: 0,
        city: '',
        avatar: '',
        theater: '',
        education: 0,
        constitution: 0,
        eyeColor: 0,
        hairColor: 0,
        height: 0,
        weight: 0,
        skills: [],
        filmography: [],
        media: [],
        photos: [],
        videos: []
    } as IActor

    constructor() {
        makeAutoObservable(this)
    }

    setLoading(bool: boolean) {
        this.isLoading = bool
    }

    setCount(count: number) {
        this.count = count
    }

    setPages(pages: number) {
        this.pages = pages
    }

    setLoadingPhotos(bool: boolean) {
        this.isLoadingPhotos = bool
    }

    setActors(actors: IActor[]) {
        this.actors = actors
    }

    setActor(actor: IActor) {
        this.actor = actor
    }

    changeAvatar(avatar: string) {
        this.actor.avatar = avatar
    }

    clearActor() {
        this.setActor({
            patronymic: '',
            firstName: '',
            lastName: '',
            gender: 0,
            birthDay: '',
            age: undefined,
            race: 0,
            citizenship: 0,
            city: '',
            avatar: '',
            theater: '',
            education: 0,
            constitution: 0,
            eyeColor: 0,
            hairColor: 0,
            height: 0,
            weight: 0,
            skills: [],
            filmography: [],
            media: [],
            photos: [],
            videos: []
        } as IActor)
    }

    toAllArray() {
        if (this.pages === 1) {
            this.allActors = this.actors
        } else {
            this.allActors = [...this.allActors, ...this.actors]
        }
    }

    async getActorsWithFilter() {

    }

    async getActors(page: number, limit: number, filter?: FilterStore) {
        this.setLoading(true)
        try {
            const response = await ActorService.fetchActors(page, limit, filter)
            const { actors, count, pages } = response.data
            this.clearActor()
            this.setActors(actors)
            this.setCount(count)
            this.setPages(pages)
            this.toAllArray()
            console.log({ actors, count, pages })
            return actors
        } catch (error) {
            this.setActors([])
            console.log(error)
            return []
        } finally {
            this.setLoading(false)
        }
    }

    async getActor(id: string) {
        this.clearActor()
        this.setLoading(true)
        try {
            const response = await ActorService.fetchActor(id)
            this.setActor(response.data)
        } catch (error) {
            console.log(error)
        } finally {
            this.setLoading(false)
        }
    }

    getFullName() {
        return [this.actor.lastName, this.actor.firstName].join(' ')
    }




}