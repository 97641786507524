import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { env } from "../config";
import { Context } from "../index";
const Home = () => {
  useEffect(() => {
    document.title = "Главная";
  }, []);
  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const banner = (
    <Container
      style={{
        backgroundColor: "aliceblue",
        backgroundRepeat: "no-repeat",
        marginTop: "15px",
        marginBottom: "15px",
        textAlign: "center",
        padding: "5px",
        color: "#2f60bd",
      }}
    >
      <span>
        <strong style={{ fontWeight: 700 }}>
          Проект реализован АНО центр креативных индустрий «Медиастарт»
        </strong>
      </span>
      <br />
      <span>
        <strong style={{ fontWeight: 700 }}>
          при поддержке Фонда содействия гражданскому обществу Республики
          Башкортостан
        </strong>
      </span>
    </Container>
  );

  const list_1 = (
    <Container
      style={{
        width: "100%",
        minHeight: "calc(100vh - 40px)",
        backgroundColor: "aliceblue",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        filter: "blur(0px) opacity(100%)",
      }}
    >
      <Row
        style={{
          height: "calc(100vh - 40px)",
        }}
      >
        <Col
          md={6}
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "20%",
              bottom: "0%",
              padding: 45,
              borderTopRightRadius: "25px",
              borderRadius: "15px",
              width: "100%",
              backgroundColor: "aliceblue",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              filter: "blur(0px) opacity(98%)",
            }}
          >
            <h2 style={{ fontWeight: 400 }}>
              Портал для <br /> актёров, агентов,
              <br /> кастинг директоров и режиссёров
            </h2>
            <br />
            <Row>
              <Col md={12}>
                <h2 style={{ color: "#2f60bd", fontWeight: 300 }}>
                  600+ Актеров
                </h2>
              </Col>
              <Col md={6} xs={12}>
                <h2 style={{ color: "#2f60bd", fontWeight: 300 }}>
                  1 000+ Видео
                </h2>
              </Col>
              <Col md={6} xs={12}>
                <h2 style={{ color: "#2f60bd", fontWeight: 300 }}>
                  3 000+ Фото
                </h2>
              </Col>
              <Col md={12}>
                <div className="d-grid">
                  <br />
                  <Button
                    variant="primary"
                    size="lg"
                    onClick={() => window.open(`/actors`)}
                  >
                    <span>Актерская база республики Башкортостан</span>
                    <br />
                    <small style={{ fontSize: ".735rem", fontWeight: 200 }}>
                      все профессиональные актеры региона в одной базе
                    </small>
                  </Button>
                </div>
                <div>
                  <br />
                  <small
                    style={{
                      color: "#2f60bd",
                      fontWeight: 200,
                      fontSize: ".735rem",
                    }}
                  >
                    <span>
                      Проект реализован АНО центр креативных индустрий
                      «Медиастарт»
                    </span>
                    <br />
                    <span>
                      при поддержке Фонда содействия гражданскому обществу
                      Республики Башкортостан
                    </span>
                  </small>
                </div>
              </Col>
              <Col md={12} xs={12}></Col>
            </Row>
          </div>
        </Col>

        <Col
          md={6}
          xs={12}
          style={{
            padding: 10,
            height: "100%",
            backgroundImage: `url(${env.url}/public/2.jpg)`,
            backgroundColor: "aliceblue",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "right",
            filter: "blur(0px) opacity(90%)",
            transform: "scaleX(-1)",
          }}
          className="d-none d-md-block"
        >
          <div></div>
        </Col>
      </Row>
    </Container>
  );
  const list_2 = (
    <Container
      style={{
        width: "100%",
        minHeight: "calc(100vh - 40px)",
        backgroundColor: "aliceblue",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        filter: "blur(0px) opacity(100%)",
      }}
    >
      <div className="d-none d-md-block">
        <Row>
          <Col md={6} ms={12} style={{ padding: 10 }}>
            <div
              style={{
                padding: 10,
                borderTopRightRadius: "25px",
                borderRadius: "15px",
                width: "100%",
                height: "100%",
                minHeight: 400,
                backgroundImage: `url(${env.url}/public/2.jpg)`,
                backgroundColor: "aliceblue",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                filter: "blur(0px) opacity(98%)",
              }}
            ></div>
          </Col>
          <Col md={6} ms={12} style={{ padding: 10 }}>
            <br />
            <h2 style={{ padding: "5%" }}>Кинокомпаниям</h2>
            <Row>
              <Col md={6} xs={12}>
                <ul
                  style={{
                    listStyle: "none",
                  }}
                >
                  <li
                    style={{
                      display: "flex",
                      marginBottom: "24px",
                      fontSize: "18px",
                      lineHeight: "21px",
                    }}
                  >
                    <span
                      className="triangle"
                      style={{
                        fontSize: "18px",
                        lineHeight: "21px",
                        width: "10px",
                        height: "10px",
                        flexShrink: 0,
                        clipPath:
                          "polygon(0% 0, 100% 100%, 100% 100%, 100% 0%, 100% 0%)",
                        marginTop: "6px",
                        marginRight: "12px",
                        backgroundColor: "#5E90EB",
                      }}
                    />
                    <span>Автоматизация процессов кастинга</span>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      marginBottom: "24px",
                      fontSize: "18px",
                      lineHeight: "21px",
                    }}
                  >
                    <span
                      className="triangle"
                      style={{
                        fontSize: "18px",
                        lineHeight: "21px",
                        width: "10px",
                        height: "10px",
                        flexShrink: 0,
                        clipPath:
                          "polygon(0% 0, 100% 100%, 100% 100%, 100% 0%, 100% 0%)",
                        marginTop: "6px",
                        marginRight: "12px",
                        backgroundColor: "#5E90EB",
                      }}
                    />
                    <span>Удобное хранение и систематизация проб</span>
                  </li>
                </ul>
              </Col>
              <Col md={6} xs={12}>
                <ul
                  style={{
                    listStyle: "none",
                  }}
                >
                  <li
                    style={{
                      display: "flex",
                      marginBottom: "24px",
                      fontSize: "18px",
                      lineHeight: "21px",
                    }}
                  >
                    <span
                      className="triangle"
                      style={{
                        fontSize: "18px",
                        lineHeight: "21px",
                        width: "10px",
                        height: "10px",
                        flexShrink: 0,
                        clipPath:
                          "polygon(0% 0, 100% 100%, 100% 100%, 100% 0%, 100% 0%)",
                        marginTop: "6px",
                        marginRight: "12px",
                        backgroundColor: "#5E90EB",
                      }}
                    />
                    <span>Всегда актуальная информация об актерах</span>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      marginBottom: "24px",
                      fontSize: "18px",
                      lineHeight: "21px",
                    }}
                  >
                    <span
                      className="triangle"
                      style={{
                        fontSize: "18px",
                        lineHeight: "21px",
                        width: "10px",
                        height: "10px",
                        flexShrink: 0,
                        clipPath:
                          "polygon(0% 0, 100% 100%, 100% 100%, 100% 0%, 100% 0%)",
                        marginTop: "6px",
                        marginRight: "12px",
                        backgroundColor: "#5E90EB",
                      }}
                    />
                    <span>Организация собственной кастинг-базы компании</span>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col md={6} ms={12} style={{ padding: 10 }}>
            <br />
            <h2 style={{ padding: "5%" }}>Актерам</h2>
            <Row>
              <Col md={6} xs={12}>
                <ul
                  style={{
                    listStyle: "none",
                  }}
                >
                  <li
                    style={{
                      display: "flex",
                      marginBottom: "24px",
                      fontSize: "18px",
                      lineHeight: "21px",
                    }}
                  >
                    <span>Автоматизация процессов кастинга</span>
                    <span
                      className="triangle"
                      style={{
                        fontSize: "18px",
                        lineHeight: "21px",
                        width: "10px",
                        height: "10px",
                        flexShrink: 0,
                        clipPath:
                          "polygon(0% 0, 100% 100%, 100% 100%, 100% 0%, 100% 0%)",
                        marginTop: "6px",
                        marginRight: "12px",
                        backgroundColor: "#5E90EB",
                      }}
                    />
                  </li>
                  <li
                    style={{
                      display: "flex",
                      marginBottom: "24px",
                      fontSize: "18px",
                      lineHeight: "21px",
                    }}
                  >
                    <span>Удобное хранение и систематизация проб</span>
                    <span
                      className="triangle"
                      style={{
                        fontSize: "18px",
                        lineHeight: "21px",
                        width: "10px",
                        height: "10px",
                        flexShrink: 0,
                        clipPath:
                          "polygon(0% 0, 100% 100%, 100% 100%, 100% 0%, 100% 0%)",
                        marginTop: "6px",
                        marginRight: "12px",
                        backgroundColor: "#5E90EB",
                      }}
                    />
                  </li>
                </ul>
              </Col>
              <Col md={6} xs={12}>
                <ul
                  style={{
                    listStyle: "none",
                  }}
                >
                  <li
                    style={{
                      display: "flex",
                      marginBottom: "24px",
                      fontSize: "18px",
                      lineHeight: "21px",
                    }}
                  >
                    <span>Всегда актуальная информация об актерах</span>
                    <span
                      className="triangle"
                      style={{
                        fontSize: "18px",
                        lineHeight: "21px",
                        width: "10px",
                        height: "10px",
                        flexShrink: 0,
                        clipPath:
                          "polygon(0% 0, 100% 100%, 100% 100%, 100% 0%, 100% 0%)",
                        marginTop: "6px",
                        marginRight: "12px",
                        backgroundColor: "#5E90EB",
                      }}
                    />
                  </li>
                  <li
                    style={{
                      display: "flex",
                      marginBottom: "24px",
                      fontSize: "18px",
                      lineHeight: "21px",
                    }}
                  >
                    <span>Организация собственной кастинг-базы компании</span>
                    <span
                      className="triangle"
                      style={{
                        fontSize: "18px",
                        lineHeight: "21px",
                        width: "10px",
                        height: "10px",
                        flexShrink: 0,
                        clipPath:
                          "polygon(0% 0, 100% 100%, 100% 100%, 100% 0%, 100% 0%)",
                        marginTop: "6px",
                        marginRight: "12px",
                        backgroundColor: "#5E90EB",
                      }}
                    />
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col md={6} ms={12} style={{ padding: 10 }}>
            <div
              style={{
                borderTopRightRadius: "25px",
                borderRadius: "15px",
                width: "100%",
                height: "100%",
                minHeight: 400,
                backgroundImage: `url(${env.url}/public/3.jpg)`,
                backgroundColor: "aliceblue",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                filter: "blur(0px) opacity(98%)",
              }}
            ></div>
          </Col>
        </Row>
      </div>

      <div className="d-md-none d-xs-block">
        <Row>
          <Col md={6} ms={12} style={{ padding: 10 }}>
            <div
              style={{
                padding: 10,
                borderTopRightRadius: "25px",
                borderRadius: "15px",
                width: "100%",
                height: "100%",
                minHeight: 400,
                backgroundImage: `url(${env.url}/public/2.jpg)`,
                backgroundColor: "aliceblue",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                filter: "blur(0px) opacity(98%)",
              }}
            ></div>
          </Col>
          <Col md={6} ms={12} style={{ padding: 10 }}>
            <br />
            <h2 style={{ padding: "5%" }}>Кинокомпаниям</h2>
            <Row>
              <Col md={6} xs={12}>
                <ul
                  style={{
                    listStyle: "none",
                  }}
                >
                  <li
                    style={{
                      display: "flex",
                      marginBottom: "24px",
                      fontSize: "18px",
                      lineHeight: "21px",
                    }}
                  >
                    <span
                      className="triangle"
                      style={{
                        fontSize: "18px",
                        lineHeight: "21px",
                        width: "10px",
                        height: "10px",
                        flexShrink: 0,
                        clipPath:
                          "polygon(0% 0, 100% 100%, 100% 100%, 100% 0%, 100% 0%)",
                        marginTop: "6px",
                        marginRight: "12px",
                        backgroundColor: "#5E90EB",
                      }}
                    />
                    <span>Автоматизация процессов кастинга</span>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      marginBottom: "24px",
                      fontSize: "18px",
                      lineHeight: "21px",
                    }}
                  >
                    <span
                      className="triangle"
                      style={{
                        fontSize: "18px",
                        lineHeight: "21px",
                        width: "10px",
                        height: "10px",
                        flexShrink: 0,
                        clipPath:
                          "polygon(0% 0, 100% 100%, 100% 100%, 100% 0%, 100% 0%)",
                        marginTop: "6px",
                        marginRight: "12px",
                        backgroundColor: "#5E90EB",
                      }}
                    />
                    <span>Удобное хранение и систематизация проб</span>
                  </li>
                </ul>
              </Col>
              <Col md={6} xs={12}>
                <ul
                  style={{
                    listStyle: "none",
                  }}
                >
                  <li
                    style={{
                      display: "flex",
                      marginBottom: "24px",
                      fontSize: "18px",
                      lineHeight: "21px",
                    }}
                  >
                    <span
                      className="triangle"
                      style={{
                        fontSize: "18px",
                        lineHeight: "21px",
                        width: "10px",
                        height: "10px",
                        flexShrink: 0,
                        clipPath:
                          "polygon(0% 0, 100% 100%, 100% 100%, 100% 0%, 100% 0%)",
                        marginTop: "6px",
                        marginRight: "12px",
                        backgroundColor: "#5E90EB",
                      }}
                    />
                    <span>Всегда актуальная информация об актерах</span>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      marginBottom: "24px",
                      fontSize: "18px",
                      lineHeight: "21px",
                    }}
                  >
                    <span
                      className="triangle"
                      style={{
                        fontSize: "18px",
                        lineHeight: "21px",
                        width: "10px",
                        height: "10px",
                        flexShrink: 0,
                        clipPath:
                          "polygon(0% 0, 100% 100%, 100% 100%, 100% 0%, 100% 0%)",
                        marginTop: "6px",
                        marginRight: "12px",
                        backgroundColor: "#5E90EB",
                      }}
                    />
                    <span>Организация собственной кастинг-базы компании</span>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col md={6} ms={12} style={{ padding: 10 }}>
            <div
              style={{
                borderTopRightRadius: "25px",
                borderRadius: "15px",
                width: "100%",
                height: "100%",
                minHeight: 400,
                backgroundImage: `url(${env.url}/public/3.jpg)`,
                backgroundColor: "aliceblue",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                filter: "blur(0px) opacity(98%)",
              }}
            ></div>
          </Col>
          <Col md={6} ms={12} style={{ padding: 10 }}>
            <br />
            <h2 style={{ padding: "5%" }}>Актерам</h2>
            <Row>
              <Col md={6} xs={12}>
                <ul
                  style={{
                    listStyle: "none",
                  }}
                >
                  <li
                    style={{
                      display: "flex",
                      marginBottom: "24px",
                      fontSize: "18px",
                      lineHeight: "21px",
                    }}
                  >
                    <span>Автоматизация процессов кастинга</span>
                    <span
                      className="triangle"
                      style={{
                        fontSize: "18px",
                        lineHeight: "21px",
                        width: "10px",
                        height: "10px",
                        flexShrink: 0,
                        clipPath:
                          "polygon(0% 0, 100% 100%, 100% 100%, 100% 0%, 100% 0%)",
                        marginTop: "6px",
                        marginRight: "12px",
                        backgroundColor: "#5E90EB",
                      }}
                    />
                  </li>
                  <li
                    style={{
                      display: "flex",
                      marginBottom: "24px",
                      fontSize: "18px",
                      lineHeight: "21px",
                    }}
                  >
                    <span>Удобное хранение и систематизация проб</span>
                    <span
                      className="triangle"
                      style={{
                        fontSize: "18px",
                        lineHeight: "21px",
                        width: "10px",
                        height: "10px",
                        flexShrink: 0,
                        clipPath:
                          "polygon(0% 0, 100% 100%, 100% 100%, 100% 0%, 100% 0%)",
                        marginTop: "6px",
                        marginRight: "12px",
                        backgroundColor: "#5E90EB",
                      }}
                    />
                  </li>
                </ul>
              </Col>
              <Col md={6} xs={12}>
                <ul
                  style={{
                    listStyle: "none",
                  }}
                >
                  <li
                    style={{
                      display: "flex",
                      marginBottom: "24px",
                      fontSize: "18px",
                      lineHeight: "21px",
                    }}
                  >
                    <span>Всегда актуальная информация об актерах</span>
                    <span
                      className="triangle"
                      style={{
                        fontSize: "18px",
                        lineHeight: "21px",
                        width: "10px",
                        height: "10px",
                        flexShrink: 0,
                        clipPath:
                          "polygon(0% 0, 100% 100%, 100% 100%, 100% 0%, 100% 0%)",
                        marginTop: "6px",
                        marginRight: "12px",
                        backgroundColor: "#5E90EB",
                      }}
                    />
                  </li>
                  <li
                    style={{
                      display: "flex",
                      marginBottom: "24px",
                      fontSize: "18px",
                      lineHeight: "21px",
                    }}
                  >
                    <span>Организация собственной кастинг-базы компании</span>
                    <span
                      className="triangle"
                      style={{
                        fontSize: "18px",
                        lineHeight: "21px",
                        width: "10px",
                        height: "10px",
                        flexShrink: 0,
                        clipPath:
                          "polygon(0% 0, 100% 100%, 100% 100%, 100% 0%, 100% 0%)",
                        marginTop: "6px",
                        marginRight: "12px",
                        backgroundColor: "#5E90EB",
                      }}
                    />
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Container>
  );
  const list_3 = (
    <Container
      style={{
        marginTop: "0px",
        width: "100%",
        minHeight: "calc(100vh - 40px)",
        backgroundImage: `url(${env.url}/public/8.jpg)`,
        backgroundColor: "aliceblue",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        filter: "blur(0px) opacity(100%)",
      }}
    >
      <Row
        style={{
          padding: "10%",
        }}
      >
        <Col md={6} xs={12}>
          <div
            style={{
              minHeight: "10vh",
              padding: "10%",
              margin: 10,
              backgroundColor: "aliceblue",
            }}
          >
            <h2>Как попасть в базу?</h2>
            <p>
              База формируется силами нашей команды и постоянно дополняется.
              Если вы актер и хотите чтобы ваш профиль появилась в базе,
              заполните анкету.
            </p>
            <div className="d-grid">
              <br />
              <Button
                variant="primary"
                size="lg"
                onClick={() =>
                  window.open(
                    `https://docs.google.com/forms/d/e/1FAIpQLScZnky7noaDk1dUana_4LBa4AqkbdMU4TrKXJxPm5q09wG8LQ/viewform?embedded=true`
                  )
                }
              >
                Заполнить анкету
              </Button>
            </div>
          </div>
        </Col>
        <Col md={6} xs={12}>
          <div
            style={{
              minHeight: "10vh",
              padding: "10%",
              margin: 10,
              backgroundColor: "aliceblue",
            }}
          >
            <h2>Кто может видеть контакты?</h2>
            <br />
            <p>
              В настоящее время контакты актёров в анкетах не указываются, и
              предоставляются только по запросу продюсеров, режиссеров или
              кастинг-директоров.
            </p>
          </div>
        </Col>

        <Col md={6} xs={12}></Col>
        <Col md={12} xs={12}>
          <div
            style={{
              minHeight: "10vh",
              padding: "5%",
              margin: 10,
              backgroundColor: "aliceblue",
            }}
          >
            <h2>Остались вопросы?</h2>
            <br />
            <p>Напишите нам и мы обязательно ответим на все вопросы.</p>
            <div className="d-grid">
              <br />
              <Button
                variant="primary"
                size="lg"
                onClick={() => window.open(`mailto:${env.mail2}`)}
              >
                Задать вопрос
              </Button>
            </div>
          </div>
        </Col>
        <Col md={12} xs={12}>
          <div
            style={{
              minHeight: "10vh",
              padding: "5%",
              margin: 10,
              backgroundColor: "aliceblue",
            }}
          >
            <h2>Партнеры</h2>
            <div className="d-grid">
              <Row>
                <Col md={3} xs={12}>
                  <img
                    src="/images/logo1.png"
                    style={{
                      width: "100%",
                      margin: "auto",
                      marginTop: "25%",
                      cursor: "pointer",
                    }}
                    onClick={() => openInNewTab("http://kinorb.ru")}
                  />
                </Col>
                <Col md={3} xs={12}>
                  <img
                    src="/images/logo2.png"
                    style={{ width: "100%", margin: "auto", cursor: "pointer" }}
                    onClick={() => openInNewTab("https://грантыглавы102.рф")}
                  />
                </Col>
                <Col md={3} xs={12}>
                  <img
                    src="/images/logo3.jpeg"
                    style={{
                      width: "100%",
                      margin: "auto",
                      marginTop: "25%",
                      cursor: "pointer",
                    }}
                    onClick={() => openInNewTab("http://kinokarlugas.ru")}
                  />
                </Col>
                <Col md={3} xs={12} style={{ textAlign: "center" }}>
                  <img
                    src="/images/logo4.png"
                    style={{
                      width: "100%",
                      maxWidth: "100px",
                      margin: "auto",
                      cursor: "pointer",
                    }}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );

  const old = (
    <>
      {list_1}
      <br />
      {list_2}
      <br />
      {list_3}
    </>
  );
  const first = (
    <Container
      style={{
        width: "100%",
        minHeight: "calc(100vh - 40px)",
        backgroundImage: `url(${env.url}/public/2.jpg)`,
        backgroundColor: "aliceblue",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        filter: "blur(0px) opacity(100%)",
      }}
    >
      <Row
        style={{
          height: "calc(100vh - 40px)",
          position: "relative",
        }}
      >
        <Col
          md={12}
          style={{
            textAlign: "center",
            color: "#fff",
            marginTop: "40px",
          }}
        >
          <h1>Актерская база республики Башкортостан</h1>
          <h2>Все профессиональные актеры региона в одной базе</h2>
        </Col>
        <Col
          md={12}
          style={{
            margin: "auto",
            position: "absolute",
            bottom: "40px",
          }}
        >
          <div
            style={{
              textAlign: "center",
              color: "#fff",
            }}
          >
            <h1>КИНОСТАРТ</h1>
          </div>
          <div
            style={{
              margin: "auto",
              padding: 45,
              borderTopRightRadius: "25px",
              borderRadius: "15px",
              width: "100%",
              maxWidth: "600px",
              backgroundColor: "aliceblue",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              filter: "blur(0px) opacity(98%)",
            }}
          >
            <Row>
              <h1 style={{ fontWeight: 400 }}>
                Портал для <br /> актёров, агентов,
                <br /> кастинг директоров и режиссёров
              </h1>
              <br />
              <Col md={12}>
                <h1 style={{ color: "#2f60bd", fontWeight: 300 }}>
                  600+ Актеров
                </h1>
              </Col>
              <Col md={6} xs={12}>
                <h2 style={{ color: "#2f60bd", fontWeight: 300 }}>
                  1000+ Видео
                </h2>
              </Col>
              <Col md={6} xs={12}>
                <h2 style={{ color: "#2f60bd", fontWeight: 300 }}>
                  3000+ Фото
                </h2>
              </Col>
            </Row>
          </div>

          <div
            className="d-grid"
            style={{
              width: "80%",
              maxWidth: "600px",
              margin: "auto",
            }}
          >
            <br />
            <Button
              variant="primary"
              size="lg"
              onClick={() => window.open(`/actors`)}
            >
              Подобрать актера
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
  return (
    <>
      {list_1}
      {list_3}
    </>
  );
};

export default Home;
