import React, { useState } from 'react'
import { FaLongArrowAltLeft, FaLongArrowAltRight } from 'react-icons/fa'

interface ControlProps {
    leftClick: (event: React.MouseEvent<SVGElement, MouseEvent>) => void
    rightClick: (event: React.MouseEvent<SVGElement, MouseEvent>) => void
    isHidden?: boolean
}

const CustomArrowsControl = ({ leftClick, rightClick, isHidden = false}: ControlProps) => {


    const [leftHoverArrow, setLeftHoverArrow] = useState<boolean>(false)
    const [rightHoverArrow, setRightHoverArrow] = useState<boolean>(false)

    const toogleLeft = () => setLeftHoverArrow(!leftHoverArrow)
    const toogleRight = () => setRightHoverArrow(!rightHoverArrow)

    const linkStyleLeft = leftHoverArrow ? { color: 'red' } : { color: 'blue' }
    const linkStyleRight = rightHoverArrow ? { color: 'red' } : { color: 'blue' }

    if (!isHidden) return (<></>)
    return (
        <div className="d-flex justify-content-end" style={{ paddingRight: 10 }} >
            <FaLongArrowAltLeft onClick={leftClick} onMouseEnter={toogleLeft} onMouseLeave={toogleLeft} style={{ ...linkStyleLeft, padding: 5,  fontSize: 25, cursor: 'pointer' }} />
            <FaLongArrowAltRight onClick={rightClick} onMouseEnter={toogleRight} onMouseLeave={toogleRight} style={{ ...linkStyleRight, padding: 5,  fontSize: 25, cursor: 'pointer' }} />
        </div >
    )
}

export default CustomArrowsControl