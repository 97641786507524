

import React from 'react'
import { Col, Spinner } from 'react-bootstrap'

import ReactPlayer from 'react-player/lazy'

interface ItemProps {
    video: string
    id: number,
}


const CustomSliderVideoItem = React.memo(({ id, video }: ItemProps) => {
    let isHidden = false
    if (id >= 1) {
        isHidden = true
    }

    const renderVideo = React.useMemo(() => {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
        var match = video.match(regExp)
        if (match && match[7].length === 11) {
            return (
                <ReactPlayer url={video} height={'198px'} width={'100%'} />
            )
        } else {
            return (
                <div style={{ height: "198px", width: "100%", position: 'relative' }}>
                    <div style={{
                        textAlign: 'center',
                        marginTop: 'calc(25% + 10px)',
                        height: '200px',
                        position: 'absolute',
                        left: 'calc(50% - 25px)'
                    }}>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>

                </div>
            )
        }
    }, [video])


    return (
        <Col md={12} xs={12} key={id} hidden={isHidden} >
            <div className="gallery" style={{ position: 'relative', maxHeight: "100%", marginTop: '11px' }}>
                <div style={{ cursor: "pointer", marginLeft: "10px", marginRight: "-10px" }}>
                    {renderVideo}
                </div>
            </div>
        </Col>
    )
})

export default CustomSliderVideoItem