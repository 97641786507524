import * as React from "react";
import { Form } from "react-bootstrap";

interface PropsRange {
  label: string;
  min: number | null;
  max: number | null;
  minValue: number | null;
  maxValue: number | null;
  setMin: Function;
  setMax: Function;
}

const CustomMultiRange = ({
  label,
  min,
  max,
  minValue,
  maxValue,
  setMin,
  setMax,
}: PropsRange) => {
  const checkMinVal = (value: number | null) => {
    if (value && max && maxValue) {
      console.log({ value });
      if (value <= max && value <= maxValue) {
        setMin(value);
      } else {
        if (value > max) {
          setMin(maxValue);
        } else {
          setMin(min);
        }
      }
      return value;
    } else {
      setMin(min);
    }
  };

  const checkMaxVal = (value: number | null) => {
    if (value && typeof max === "number" && typeof minValue === "number") {
      if (value <= max && value >= minValue) {
        setMax(value);
      } else {
        if (value > minValue) {
          setMax(max);
        } else {
          setMax(minValue + 1);
        }
      }
      return value;
    } else {
      setMax(max);
    }
  };

  const onChangeMin = (val: React.ChangeEvent<any>) => {
    console.log(val.target.value)
    if (val.target.value) {
      const value = parseInt(val.target.value);
      return checkMinVal(value);
    } else {
      setMin(null);
    }
    return "";
  };

  const onChangeMax = (val: React.ChangeEvent<any>) => {
    if (val.target.value) {
      const value = parseInt(val.target.value);
      return checkMaxVal(value);
    } else {
      setMax(null);
    }
    return "";
  };

  return (
    <>
      <Form.Text>{label}</Form.Text>
      <div style={{ textAlign: "center", display: "flex" }}>
        <span style={{ padding: "0.375rem 0.75rem" }}>
          <Form.Text>от</Form.Text>{" "}
        </span>
        <Form.Control
          type="number"
          min={min ? min : ""}
          max={max ? max : ""}
          value={minValue !== null && minValue >= 0 ? minValue : ""}
          onChange={(val) => onChangeMin(val)}
          onBlur={() => checkMinVal(minValue)}
        />
        <span style={{ padding: "0.375rem 0.75rem" }}>
          {" "}
          <Form.Text>до</Form.Text>
        </span>
        <Form.Control
          type="number"
          min={minValue ? minValue + 1 : 0}
          max={max ? max : ""}
          value={maxValue ? maxValue : ""}
          onChange={(val) => onChangeMax(val)}
          onBlur={() => checkMaxVal(maxValue)}
        />
      </div>
    </>
  );
};

export default CustomMultiRange;
