import React from "react"
import { Button, Container, Modal, Stack } from 'react-bootstrap';
interface Props {
    show: boolean
    handleClose: () => void
    id?: string
    title?: string
    children?: JSX.Element | JSX.Element[]

}
const openHandler = (id: string) => {
    window.open(`/actors/${id}`, "_blank")
}
const ModalActor = ({ show, handleClose, id = "", title, children }: Props) => {
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton style={{ border: 'none' }}>
                    <Container style={{ paddingLeft: 5 }}>
                        <Modal.Title style={{ color: '#2f60bd', fontWeight: 300 }}>
                            {title}
                        </Modal.Title>
                    </Container>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
                <Container style={{ margin: 5, marginBottom: 15 }}>
                    <Stack direction="horizontal" gap={4} >
                        <Button variant="primary" onClick={() => openHandler(id)} style={{ fontSize: 20 }}> Открыть страницу актера</Button>
                        <Button variant="blank" onClick={handleClose} style={{ fontSize: 20, fontWeight: 600 }}>
                            Закрыть
                        </Button>
                    </Stack>
                </Container>
            </Modal>
        </>
    )
}

export default ModalActor