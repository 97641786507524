import React, { useContext } from "react"
import { Container, Form, InputGroup, Row, Col } from "react-bootstrap"
import { BsFilter, BsGrid3X2GapFill, BsFillGrid1X2Fill, BsFillGrid3X3GapFill } from "react-icons/bs"
import { Context } from '../../../index'
import { observer } from "mobx-react-lite"

interface Props {
    setSize: (value: React.SetStateAction<number>) => void
    setShowFilter: (value: React.SetStateAction<boolean>) => void
}
const ActorsRowsHeader = ({ setSize, setShowFilter }: Props) => {

    const { filterStore } = useContext(Context)
    return (
        <div style={{ position: 'sticky', top: '56px', background: 'white', zIndex: 1000 }}>
            <br />
            <Container className="d-none d-lg-block">
                <InputGroup className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder="Введите ФИО актера"
                        required
                        value={filterStore.fio}
                        onInput={(val) => filterStore.fio = val.currentTarget.value}
                        style={{ borderRight: 'none' }}
                    />
                    <InputGroup.Text id="basic-addon1" style={{ cursor: 'pointer', backgroundColor: 'white', borderLeft: 'none' }}>🔍</InputGroup.Text>
                </InputGroup>
            </Container>
            <Container>
                <Row>
                    <Col md={3} xs={10}>
                        <Row>
                            <Col md={2} xs={3} style={{ textAlign: 'center', cursor: "pointer" }} onClick={() => { setSize(1) }}>
                                <BsFillGrid3X3GapFill />
                            </Col>
                            <Col md={2} xs={3} style={{ textAlign: 'center', cursor: "pointer" }} onClick={() => { setSize(2) }}>
                                <BsFillGrid1X2Fill />
                            </Col>

                            <Col md={2} xs={3} style={{ textAlign: 'center', cursor: "pointer" }} onClick={() => { setSize(3) }}>
                                <BsGrid3X2GapFill />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={0} xs={2} className="d-lg-none d-xs-block">
                        <BsFilter style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => setShowFilter(true)} />
                    </Col>
                </Row>
            </Container>
            <br />
        </div>
    )
}

export default observer(ActorsRowsHeader)