import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom"
import CustomThree from "../../components/CustomThree/CustomThree";
import Actor from './Actor'

const One = () => {

    const { id } = useParams<{ id: string }>()

    return (
        <Container>
            <br />
            <Row>
                <Col md={10} xs={12}>
                    <Actor id={id} showFullname={true} socialMediaColSize={4} avatarHeight={'515px'} />
                </Col>
                <Col md={2} xs={12}>
                    <div className="sticky-top" style={{ top: '72px' }}>
                        <CustomThree actorId={id} />
                    </div>
                </Col>
            </Row>
        </Container >
    )
}

export default One