import React, { useState } from "react"
import { Badge, Button, Col, FormControl, InputGroup, Row } from "react-bootstrap"
import { observer } from "mobx-react-lite"

interface ItemsProps {
    items: string[]
}

const Skills = ({ items }: ItemsProps) => {
    const colors = [
        'primary',
        'secondary',
        'success',
        'danger',
        'warning',
        'info',
        'light',
        'dark',
    ]

    const renderBadges = () => {
        return items.map((item, id) => badgeBody(item, id))
    }

    const badgeBody = (item: string, id: number) => {
        const color = colors[id % colors.length]
        const textColor = (color === 'warning' || color === 'light') ? 'dark' : 'light'
        return (
            <Col xs={'auto'} md={'auto'} key={id}>
                <div className="gallery" style={{ position: 'relative' }}>
                    <div style={{ cursor: "pointer" }}>
                        <h4>
                            <Badge bg={color} text={textColor} style={{ fontSize: 15, opacity: 0.95 }}>
                                {item}
                            </Badge>
                        </h4>
                    </div>
                </div>
            </Col>
        )
    }

    return (
        <>
            {renderBadges()}
        </>
    )
}

export default observer(Skills)