import 'bootstrap/dist/css/bootstrap.min.css'

import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Container } from 'react-bootstrap';

import Home from './pages/Home'
import Collection from './pages/Collection'
import Help from './pages/Help'
import About from './pages/About'
import Privacy from './pages/Privacy'
import Page404 from './pages/Page404'
import List from './pages/Actors/List'
import One from './pages/Actors/One'

import NavbarComponent from "./components/NavbarComponent"

const App = () => {

  return (
    <Router>
      <NavbarComponent />
      <Container>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/collections" component={Collection} />
          <Route exact path="/about" component={About} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/actors" component={List} />
          <Route exact path="/actors/:id" component={One} />
          <Route component={Page404} />
        </Switch>
      </Container>
    </Router>
  )
}

export default App
