import '../custom.css'
import React from "react"
import { Row } from 'react-bootstrap'
import CustomSliderHeader from '../CustomSliderHeader'
import CustomSliderVideoItem from './CustomSliderVideoItem'
import { observer } from "mobx-react-lite"
interface Props {
    items: string[]
}
enum TypeAddForm {
    photo,
    video
}
const CustomVideoSlider = ({ items = [] }: Props) => {
    return (
        <div>
            <div style={{ marginLeft: -10, marginRight: 10, userSelect: "none" }} >
                <CustomSliderHeader label="Видео" items={items} type={TypeAddForm.video} />
                <Row>
                    {items.map((video, id) => <CustomSliderVideoItem key={id} id={id} video={video} />)}
                </Row>
            </div>

        </div>
    )
}

export default observer(CustomVideoSlider)