import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { createContext } from 'react';

import { actorStore, filterStore, State, visitorStore } from './store/GlobalStore'

export const Context = createContext<State>({ actorStore, filterStore, visitorStore })

ReactDOM.render(
  <Context.Provider value={{ actorStore, filterStore, visitorStore }}>
    <App />
  </Context.Provider>, document.getElementById('root')
);