import React, { useEffect, useContext, useState } from "react"
import { Container, Accordion, InputGroup, Form, Button } from "react-bootstrap"
import { Context } from '../../index'
interface ICollection {
    title: string,
    actors: Array<{ id: string, fullname: string }>
}

interface Props {
    actorId?: string
    actorName?: string
    setActor?: (id: string) => void
}

const CustomThree = ({ actorId, setActor }: Props) => {
    const { actorStore } = useContext(Context)
    const [collections, setCollections] = useState<ICollection[]>(JSON.parse(localStorage.getItem("collections") ?? "[]") ?? [] as ICollection[])
    const [newCollection, setNewCollection] = useState("")

    const [activeCollection, setActiveCollection] = useState(collections.length > 0 ? collections.length - 1 : 0)
    const [isShown, setIsShown] = useState<null | number>(null);
    const [isShownDelActor, setIsShownDelActor] = useState<null | number>(null);

    const [issetInCollection, setIssetInCollection] = useState(false)

    const checkIsset = React.useCallback(() => {
        setIssetInCollection(!!collections[activeCollection]?.actors.find(({ id, fullname }) => id === actorId))
    }, [activeCollection, actorId, collections])

    useEffect(() => {
        checkIsset()
    }, [checkIsset])

    const handleAddActorToCollection = async (actorId?: string) => {
        if (actorId) {
            const fullName = actorStore.getFullName()
            collections[activeCollection].actors = [...collections[activeCollection].actors, { id: actorId, fullname: fullName }]
            setCollections(collections)
            localStorage.setItem("collections", JSON.stringify(collections))
        }
        checkIsset()
    }

    const handleNewCollection = (title: string) => {
        setNewCollection(title)
    }

    const handleAddNewCollection = () => {
        const newArr = [...collections, {
            title: newCollection,
            actors: []
        }]
        setCollections(newArr)
        localStorage.setItem("collections", JSON.stringify(newArr))
        setActiveCollection(collections.length)
        setNewCollection("")
    }

    const handeleActiveCollection = (idx: number) => {
        setActiveCollection(idx)
    }

    const deleteHandler = (id: number, title: string) => {
        if (window.confirm(`Удалить коллекцию ${title}?`)) {
            const newArr = collections.filter((collection, idx) => idx !== id)
            setCollections(newArr)
            localStorage.setItem("collections", JSON.stringify(newArr))
            setActiveCollection(collections.length)
        }
        checkIsset()
    }

    const deleteActorHandler = (idCollection: number, titleCollection: string, idActor: string, actorName: string) => {
        if (window.confirm(`Удалить актера ${actorName} из коллекции ${titleCollection}?`)) {
            let col = collections
            let actors = col[idCollection].actors
            let newArrActors = actors.filter((actor, idx) => actor.id !== idActor)
            col[idCollection].actors = newArrActors
            setCollections(col)
            localStorage.setItem("collections", JSON.stringify(col))
        }
        checkIsset()
    }

    const renderCollection = (dataset: ICollection[]) => {
        return dataset.map(({ title, actors }, idx) => {
            return (
                <Accordion.Item
                    key={`${idx}-${title}`}
                    eventKey={`${idx}`}
                    onClick={() => handeleActiveCollection(idx)}
                >
                    <Accordion.Header style={{ width: "100%", wordBreak: "break-word" }}
                        onMouseEnter={() => setIsShown(idx)}
                        onMouseLeave={() => setIsShown(null)}
                    >
                        <span>
                            <span hidden={!(isShown === idx)} style={{ cursor: "pointer", fontSize: 12 }} onClick={() => deleteHandler(idx, title)}>
                                {`❌ `}
                            </span>

                            <span>
                                {title}
                            </span>
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        {
                            actors.length ?
                                actors.map(({ id, fullname }, idxx) => {
                                    return <p
                                        onMouseEnter={() => setIsShownDelActor(idxx)}
                                        onMouseLeave={() => setIsShownDelActor(null)}
                                        key={`${idx}-${id}-${idxx}`}
                                        onClick={() => setActor ? setActor(id) : ''}
                                        style={{ cursor: "pointer", fontSize: 14 }}
                                    >
                                        <span hidden={!(isShownDelActor === idxx)} style={{ cursor: "pointer", fontSize: 10 }} onClick={() => deleteActorHandler(idx, title, id, fullname)}>
                                            {`❌ `}
                                        </span>
                                        <span>
                                            {fullname}
                                        </span>
                                    </p>
                                })
                                :
                                <small style={{ opacity: 0.4, fontSize: 10 }}>Добавьте актера в коллекцию</small>
                        }
                    </Accordion.Body>
                </Accordion.Item >
            )
        })
    }

    const addActorInCollectionRender = (activeCollection: number, actorId?: string) => {
        let to = collections[activeCollection]?.title?.length > 0 ? collections[activeCollection]?.title : ''

        if (to.length === 0 && collections[collections.length - 1]?.title?.length > 0) {
            to = collections[collections.length - 1]?.title
            setActiveCollection(collections.length - 1)
        }

        if (actorId) {
            return (
                <Button
                    disabled={to.length === 0 || issetInCollection}
                    style={{ fontSize: 10 }}
                    variant="success"
                    onClick={() => handleAddActorToCollection(actorId)}
                >
                    Добавить актера в коллекцию {to} ?
                </Button>
            )
        }
    }

    return (
        <>
            <InputGroup style={{}}>
                <Form.Control
                    type="text"
                    placeholder="Новая коллекция"
                    required
                    value={newCollection}
                    onInput={(val: { currentTarget: { value: any; }; }) => handleNewCollection(val.currentTarget.value)}
                    style={{ borderRight: "none", borderBottomLeftRadius: 0 }}
                />
                <InputGroup.Text
                    id="basic-addon1"
                    style={{ cursor: "pointer", backgroundColor: "white", borderLeft: "none", borderBottomRightRadius: 0 }}
                    onClick={() => newCollection.length ? handleAddNewCollection() : null}

                >
                    <span>+</span>
                </InputGroup.Text>
            </InputGroup>
            <Accordion activeKey={`${activeCollection}`} defaultActiveKey={`${collections.length > 0 ? collections.length - 1 : 0}`} flush>
                {collections.length > 0 ? renderCollection(collections) :
                    <Container>
                        <small style={{ opacity: 0.4, fontSize: 10 }}>Добавьте коллекцию</small>
                    </Container>}
            </Accordion>
            <div>
                <br />
                {addActorInCollectionRender(activeCollection, actorId)}
            </div>
        </>
    )
}

export default CustomThree