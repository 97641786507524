import { FC } from "react"
import { Button } from "react-bootstrap"
import { useHistory } from "react-router-dom"
const Page404: FC = () => {
    const history = useHistory()
    async function home(event: React.SyntheticEvent) {
        event.preventDefault()
        history.push("/")
    }
    return (
        <>
            <h1>404 - Not Found!</h1>
            <Button variant="primary" type="submit" onClick={home}>
                На главную
            </Button>
        </>
    )
}

export default Page404