import React, { useEffect, useState, useContext } from "react"
import { Button, Container, Modal, Stack, Form } from 'react-bootstrap';
import { useHistory } from "react-router-dom"
import { IActor } from "../models/IActor"
import { Context } from '../index';
import { env } from "../config";
interface Props {
    actor: IActor
    show: boolean
    setShow: (val: boolean) => void
    checkUserData: () => boolean

}

const ModalSendData = ({ actor, show, setShow, checkUserData }: Props) => {
    const history = useHistory()

    const [email, setEmail] = useState(localStorage.getItem('email') ?? "")
    const [phone, setPhone] = useState(localStorage.getItem('phone') ?? "")
    const [lastName, setLastname] = useState(localStorage.getItem('lastName') ?? "")
    const [firstName, setFirstname] = useState(localStorage.getItem('firstName') ?? "")

    const { visitorStore } = useContext(Context)
    useEffect(() => {
        checkUserData()
    }, [checkUserData, show])

    useEffect(() => {
        const user = localStorage.getItem('user')
        console.log({ user })
        if (!user) {
            visitorStore.createVisit().then(() => visitorStore.visitor._id ? localStorage.setItem('user', visitorStore.visitor._id) : '')
        }

    }, [visitorStore])


    const handleConfirm = () => {
        save()
    }

    const updateVisitedList = () => {
        const visited = JSON.parse(localStorage.getItem('visited') ?? '[]')
        console.log('visited', visited)
        visited.push(actor._id)
        localStorage.setItem('visited', JSON.stringify(visited.filter((item:any) => !!item)))
        console.log(localStorage.getItem('visited'))
        localStorage.setItem('email', email)
        localStorage.setItem('phone', phone)
        localStorage.setItem('lastName', lastName)
        localStorage.setItem('firstName', firstName)
    }
    const save = async () => {
        const user = localStorage.getItem('user')
        if (!checkUserData() && user) {
            updateVisitedList()
            const upd = {
                visited: JSON.parse(localStorage.getItem('visited') ?? '[]'),
                email,
                phone,
                lastName,
                firstName
            }
            console.log({ upd })

            console.log({ user })
            await visitorStore.updateVisit(user, upd)
        }
        setShow(false)

    }

    const getPatternInput = (type: string) => {
        switch (type) {
            case 'phone':
                return /^\+?(\d{1,3})?[- .]?\(?(?:\d{2,3})\)?[- .]?\d\d\d[- .]?\d\d\d\d$/
                break;
            case 'email':
                return /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i
                break;
            default:
                return /\D/
                break;
        }
    }
    const inputHadlerVlidation = (type: string, val: string) => {
        const regExp = new RegExp(getPatternInput(type))
        return regExp.test(val)
    }

    return (
        <>
            <Modal
                show={show}
                size='sm'
                onHide={() => setShow(false)}
                backdrop="static"
                keyboard={false}
                centered

            >
                <Modal.Header closeButton style={{ border: 'none' }}>
                    <Container style={{ paddingLeft: 5 }}>
                        <Modal.Title style={{ color: '#2f60bd', fontWeight: 300 }}>
                            Заполните пожалуйста контактную форму
                        </Modal.Title>
                    </Container>
                </Modal.Header>
                <Modal.Body>
                    <Container >
                        <Form id="formFields" onSubmit={(e) => {
                            e.preventDefault()
                            handleConfirm()
                        }}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    value={email}
                                    isValid={inputHadlerVlidation('email', email)}
                                    isInvalid={!inputHadlerVlidation('email', email)}
                                    type="email"
                                    placeholder="Введите ваш email"
                                    required
                                    onChange={(val) => setEmail(val.currentTarget.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Номер телефона</Form.Label>
                                <Form.Control
                                    value={phone}
                                    isValid={inputHadlerVlidation('phone', phone)}
                                    isInvalid={!inputHadlerVlidation('phone', phone)}
                                    type="phone"
                                    placeholder="Введите ваш номер телефона"
                                    required
                                    onChange={(val) => setPhone(val.currentTarget.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Фамилия</Form.Label>
                                <Form.Control
                                    value={lastName}
                                    type="lastName"
                                    placeholder="Введите вашу фамилию"
                                    onChange={(val) => setLastname(val.currentTarget.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Имя</Form.Label>
                                <Form.Control
                                    value={firstName}
                                    isValid={firstName.length > 0 ? true : false}
                                    isInvalid={firstName.length < 0 ? true : false}
                                    type="firstName"
                                    placeholder="Введите ваше имя"
                                    required
                                    onChange={(val) => setFirstname(val.currentTarget.value)}
                                />
                            </Form.Group>
                        </Form>
                        <small style={{ opacity: 0.7 }}>Нажимая "Получить доступ к контактам" вы автоматически подтверждаете свое согласие с <a target="_blank" href={`${env.localhost_prod}privacy`}>нашей политикой кофиденциальности</a></small>
                    </Container>
                </Modal.Body>
                <Container style={{ margin: 5, marginBottom: 15 }}>
                    <Stack direction="horizontal" gap={4} >
                        <Button
                            variant="primary"
                            style={{ fontSize: 20 }}
                            form="formFields" type="submit"
                        >
                            Получить доступ к контактам
                        </Button>
                        <Button variant="blank" onClick={() => setShow(false)} style={{ fontSize: 20, fontWeight: 600 }}>
                            Закрыть
                        </Button>
                    </Stack>
                </Container>
            </Modal>
        </>
    )
}

export default ModalSendData