import { makeAutoObservable } from "mobx"
import VisitorService from "../services/VisitorService"
import { IVisitor } from '../models/IVisitor'
export default class VisitorStore {

    isLoading = false
    isLoadingPhotos = false

    visitors = [] as IVisitor[]
    visitor = {
        firstName: '',
        lastName: '',
        phone: '',
        visited: [],
        email: '',
    } as IVisitor

    constructor() {
        makeAutoObservable(this)
        void this.getVisitors()
    }

    setLoading(bool: boolean) {
        this.isLoading = bool
    }



    setVisitors(visitors: IVisitor[]) {
        this.visitors = visitors
    }

    setVisitor(visitor: IVisitor) {
        this.visitor = visitor
    }

    clearVisitors() {
        this.setVisitors([] as IVisitor[])
    }

    clearVisitor() {
        this.setVisitor({
            firstName: '',
            lastName: '',
            phone: '',
            visited: [],
            email: '',
        } as IVisitor)
    }

    clear() {
        this.clearVisitor()
        this.clearVisitors()
    }

    async createVisit() {
        this.clear()
        this.setLoading(true)
        try {
            const { data } = await VisitorService.createVisitor()
            this.setVisitor(data)
            return
        } catch (error) {
            console.log(error)
        } finally {
            this.setLoading(false)
        }
    }

    async updateVisit(id: string, info: IVisitor) {
        this.setLoading(true)
        console.log('update')
        console.log('id', id)
        try {
            if (id) {
                const { data } = await VisitorService.updateVisitor(id, info)

                console.log('data', data)
                this.setVisitor(data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            this.setLoading(false)
        }
    }

    async deleteVisit() {
        this.setLoading(true)
        try {
            if (this.visitor._id) {
                await VisitorService.deleteVisitor(this.visitor._id)
                this.clear()
            }
        } catch (error) {
            console.log(error)
        } finally {
            this.setLoading(false)
        }
    }


    async getVisitor(id: string) {
        this.clear()
        this.setLoading(true)
        try {
            const { data } = await VisitorService.fetchVisitor(id)
            this.setVisitor(data)
        } catch (error) {
            console.log(error)
        } finally {
            this.setLoading(false)
        }
    }

    async getVisitors() {
        this.setLoading(true)
        try {
            const { data } = await VisitorService.fetchVisitors()
            this.clear()
            this.setVisitors(data)
            console.log({ data })
            return data
        } catch (error) {
            this.setVisitors([])
            console.log(error)
            return []
        } finally {
            this.setLoading(false)
        }
    }



    getFullName() {
        return [this.visitor.lastName, this.visitor.firstName].join(' ')
    }

}