import React from "react"
import { Container } from 'react-bootstrap';

interface Props {
    label: string
    children?: JSX.Element | JSX.Element[]
}
const CustomCard = ({ label, children }: Props) => {
    return (
        <div style={{ border: '2px solid aliceblue', padding: 15, paddingRight: 15 }}>
            <div >
                {label ? <h4 style={{ color: '#2f60bd', fontWeight: 300, marginBottom: 15 }}>{label}</h4> : null}
                <div style={{ marginBottom: 10 }}>
                    {children ? children :
                        <div style={{ marginBottom: 15}}>
                            <p>Информация временно отсутствует</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default CustomCard