import React, { useState } from "react"
import { Container, Row, Col } from 'react-bootstrap'
import CustomThree from '../components/CustomThree/CustomThree';
import Actor from "./Actors/Actor";

const Collection = () => {

    const [actorId, setActorId] = useState('')


    const renderActor = (actorId?: string) => {
        if (actorId?.length) {
            return <Actor id={actorId} showFullname={true} socialMediaColSize={4} avatarHeight={'475px'} />
        }
        return <>
            <br />
            <Container>
                <p>
                    Выберите актера
                </p>
            </Container>
        </>
    }

    return (
        <Row>
            <Col md={2} xs={12}>
                <br />
                <CustomThree setActor={setActorId} />
            </Col>

            <Col md={10} xs={12} style={{ marginTop: 15, maxHeight: '90vh', overflow: 'auto' }}>
                {renderActor(actorId)}
            </Col>
        </Row>
    )
}

export default Collection