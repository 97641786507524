

import React from 'react'
import { Col } from 'react-bootstrap'

interface ItemProps {
    photo: string
    id: number
    onClick: Function

}


const CustomSliderPhotoItem = ({ id, photo, onClick }: ItemProps) => {

    let isHidden = false
    if (id >= 3) {
        isHidden = true
    }

    return (
        <Col md={4} xs={12} key={id} hidden={isHidden} onClick={() => onClick()}>
            <div className="gallery" style={{ position: 'relative', height: '100%' }}>
                <img
                    className={`${id > 0 ? 'd-none' : ''} d-md-block`}
                    src={photo}
                    style={{ cursor: "pointer", maxWidth: '100%', margin: 10, aspectRatio: "1", border: '2px solid aliceblue' }}
                />
            </div>
        </Col>
    )
}

export default CustomSliderPhotoItem