import React, { useState } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { IActor } from '../../../models/IActor'
import { env } from '../../../config'
import { media, MediaType } from '../../../helpers/types'
import ModalSendData from '../../../components/ModalSendData'

interface Props {
    actor: IActor
    socialMediaColSize?: number
    avatarHeight?: string
}

const ActorMainAvatar = ({ actor, socialMediaColSize = 3, avatarHeight = "480px" }: Props) => {



    const [showModal, setShowModal] = useState(false)

    const handleOpenUrl = (src: string, type: number) => {
        window.open(`${openInNewTab(src, type)}`, '_blank', 'noopener,noreferrer')
    }

    const checkUserData = () => {
        const visited = JSON.parse(localStorage.getItem('visited') ?? '[]')
        const isset = !!visited.find((contact: string) => contact === actor._id)
        console.log(isset)
        return isset
    }

    const renderIcons = () => {
        return actor.media?.filter(({ type, src }) => type !== MediaType.email && type !== MediaType.tel)?.map(({ type, src }, idx) => {
            let info = media.find(({ id, text, icon }, idxx) => {
                if (id == type) {
                    return true
                }
                return false
            })
            if (info?.id) {
                let name = src ? `${info.icon}` : `${info.icon}-disabled`
                let href = `${env.url}/public/icons/${name}.svg`
                return (
                    <Col md={socialMediaColSize} xs={3} key={`${idx}-1`}  >
                        <div
                            onClick={(event: any) => {
                                handleOpenUrl(src, type)
                                event.preventDefault();

                            }}
                            style={{
                                margin: 10
                            }}
                        >
                            <div
                                style={{ cursor: src ? 'pointer' : 'not-allowed', width: '70%', height: '70%', maxWidth: 40, maxHeight: 40, margin: 'auto', backgroundColor: 'aliceblue', borderRadius: 50, padding: '5%' }}
                            >
                                <img style={{ height: '90%', width: '90%', maxWidth: 30, maxHeight: 30, margin: '5%' }} src={href} alt={info.text} />
                            </div >
                        </div>
                    </Col>
                )
            }
        })
    }

    const socialMedia3 = () => {
        let result = [] as any
        renderIcons()?.map(el => el ? result.push(el) : '')
        if (result.length <= 6) {
            let diff = 6 - result.length
            for (let i = 1; i <= diff; i++) {
                result.push(
                    <Col md={socialMediaColSize} xs={3} key={`${i}-3`}>
                        <div style={{
                            margin: 10
                        }}
                        >
                            <div
                                style={{ cursor: 'not-allowed', width: '70%', height: '70%', maxWidth: 40, maxHeight: 40, margin: 'auto', backgroundColor: 'aliceblue', borderRadius: 50, padding: '5%' }}
                            >
                                <img style={{ height: '90%', width: '90%', maxWidth: 30, maxHeight: 30, margin: '5%' }} src={`${env.url}/public/icons/website.svg`} />
                            </div >
                        </div>
                    </Col>
                )
            }
        }
        return result
    }

    const renderMedia = () => {
        if (checkUserData()) {
            return (
                <Row>
                    {socialMedia3()}
                </Row>
            )
        }
        return (
            <div style={{ padding: 15 }}>
                <Button
                    variant="primary"
                    style={{ fontSize: 20 }}
                    onClick={() => setShowModal(true)}
                >
                    Получить доступ к контактам
                </Button>
            </div>
        )
    }

    const openInNewTab = (url: string, type: MediaType) => {
        url = 'https://' + url.replaceAll('https://', '').replaceAll('http://', '')
        let mask = ''
        switch (type) {
            case 1:
                mask = 'tel:'
                break;
            case 2:
                mask = 'mailto:'
                break;
            default:
                break;

        }
        console.log(mask + url, type)
        return mask + url
        // const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        // if (newWindow) newWindow.opener = null
    }


    return (
        <>
            <ModalSendData actor={actor} show={showModal} setShow={setShowModal} checkUserData={checkUserData} />
            <div style={{
                backgroundColor: 'aliceblue',
                backgroundImage: actor.avatar ? `url(${actor.avatar})` : 'none',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100%',
                height: avatarHeight,
                maxHeight: '80vh',
                border: '2px solid aliceblue',
            }} />
            <div style={{ margin: 5, marginTop: 20, border: '2px solid aliceblue' }}>
                {renderMedia()}
            </div>
        </>
    )
}

export default ActorMainAvatar