import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { declOfNum, getMapedData, typeToGetMapedData, Film } from "../../../helpers/types"
import { IActor } from '../../../models/IActor'
import { AiOutlineCamera, AiOutlineVideoCamera } from 'react-icons/ai'
import ModalActor from "./ModalActor"
import Actor from "../Actor"
interface Props {
    actor: IActor
}
const ActorMoreInfoRow = ({ actor }: Props) => {

    const fullName = [actor.lastName, actor.firstName].join(' ')
    const [isShown, setIsShown] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const renderFilmgraphy = (filmography?: Film[]) => {
        if (filmography && filmography.length > 0) {
            return (
                <div style={{ maxHeight: '300px' }}>
                    <p style={{ color: '#9FAAB2' }}>Фильмы</p>
                    <ul style={{ overflow: 'auto', maxHeight: '250px' }}>
                        {filmography?.map((film, index) => {
                            return (
                                <li key={index} style={{ marginLeft: '-14px' }}>
                                    <p>
                                        {film.name} - <span style={{ color: '#9FAAB2' }}>{film.character}</span>
                                    </p>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )
        }
        return (
            <>
                <p style={{ color: '#9FAAB2' }}>Фильмы</p>
                <p>-</p>
            </>
        )
    }

    return (
        <>
            <ModalActor
                title={fullName}
                id={actor._id}
                show={show}
                handleClose={handleClose}
            >
                <Actor id={actor._id} socialMediaColSize={4} avatarHeight={'515px'} />
            </ModalActor>
            <Col md={12} xs={12} style={{ cursor: 'pointer' }} onClick={handleShow}>
                <Row
                    onMouseEnter={() => setIsShown(true)}
                    onMouseLeave={() => setIsShown(false)}
                >
                    <Col md={3}>
                        <div style={{ position: 'relative' }}>
                            <div style={{
                                backgroundColor: 'aliceblue',
                                backgroundImage: actor.avatar ? `url(${actor.avatar})` : 'none',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                width: 'auto',
                                height: '300px'
                            }} />
                            <div hidden={!isShown} style={{
                                position: 'absolute',
                                bottom: 0,
                                left: '20%',
                                width: 'auto',
                                paddingLeft: '5px',
                                paddingRight: '5px',
                                height: '30px',
                                textAlign: 'center',
                                backgroundColor: "#352f2f63"
                            }}>
                                <div style={{ color: 'white' }}>
                                    <AiOutlineCamera width={20} height={20} color="white" />
                                    <span style={{ fontSize: '12px' }}> {actor.photos?.length} </span>
                                </div>
                            </div>
                            <div hidden={!isShown} style={{
                                position: 'absolute',
                                bottom: 0,
                                right: '20%',
                                width: 'auto',
                                paddingLeft: '5px',
                                paddingRight: '5px',
                                height: '30px',
                                textAlign: 'center',
                                backgroundColor: "#352f2f63"
                            }}>
                                <div style={{ color: 'white' }}>
                                    <AiOutlineVideoCamera width={20} height={20} color="white" />
                                    <span style={{ fontSize: '12px' }}> {actor.videos?.length} </span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div>
                            <div >
                                <h3>{fullName}</h3>
                                <p style={{ color: '#9FAAB2' }}>{actor.age} {declOfNum(actor.age ?? 1)}</p>
                            </div>

                            <div >
                                <p style={{ color: '#9FAAB2' }}>Образование</p>
                                <p>{getMapedData(typeToGetMapedData.education, actor.education)}</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        {renderFilmgraphy(actor.filmography)}
                    </Col>
                </Row>
                <hr />
            </Col >
        </>
    )
}

export default ActorMoreInfoRow