import React, { useState } from "react"

import { Row, Col } from "react-bootstrap"
import { IActor } from '../../../models/IActor'
import ModalActor from "./ModalActor"
import Actor from "../Actor"
interface Props {
    actor: IActor
}
const ActorPhotosRow = ({ actor }: Props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const fullName = [actor.lastName, actor.firstName].join(' ')
    return (
        <>
            <ModalActor
                title={fullName}
                id={actor._id}
                show={show}
                handleClose={handleClose}
            >
                <Actor id={actor._id} socialMediaColSize={4} avatarHeight={'515px'} />
            </ModalActor>
            <Col md={12} xs={12} style={{ cursor: 'pointer' }} onClick={handleShow}>
                <h5>{fullName}</h5>
                <hr />
                <Row>
                    {actor.photos?.map((photo, idx) => {
                        if (idx < 6) return (
                            <Col md={2} key={idx}>
                                <div style={{
                                    backgroundImage: `url(${photo})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: 'auto',
                                    height: '200px',
                                    marginBottom: '5px'
                                }} />
                            </Col>
                        )
                    })}
                </Row>
                <hr />
                <br />
            </Col>
        </>
    )
}

export default ActorPhotosRow