import "./custom.css";
import React, { useContext, useEffect, useState } from "react";

import { observer } from "mobx-react-lite";

import { Row, Container, Col } from "react-bootstrap";

import { Context } from "../../index";

import CustomFilter from "../../components/CustomFilter";
import CustomOffcanvas from "../../components/CustomOffcanvas";
import ActorsRowsHeader from "./components/ActorsRowsHeader";
import ActorsInfiniteScroll from "./components/ActorsInfiniteScroll";

import { IActor } from "../../models/IActor";

const List = () => {
  const { actorStore, filterStore } = useContext(Context);
  const [actors, setActors] = useState<IActor[]>([] as IActor[]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [limit, setLimit] = useState(10);
  const [colSize, setColSize] = useState(1);
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    document.title = "Актеры";
    setPage(1);
    setCount(10);
    getActors(page, limit);
  }, []);

  const getActors = async (page: number, limit: number) => {
    const data = await actorStore.getActors(page, limit, filterStore);
    const result = [...actors, ...(data as IActor[])] as IActor[];
    setPage(page + 1);
    setActors(result);
    setCount(actorStore.count);
  };

  const fetchMoreData = async () => {
    await getActors(page, limit);
  };

  const test = async () => {
    const data = await actorStore.getActors(1, 10, filterStore);
    const result = [...(data as IActor[])] as IActor[];
    setPage(2);
    setActors(result);
    setCount(actorStore.count);
  };
  const handleKeyPress = (event: any) => {
    console.log({ event });
    if (event.key === "Enter") {
      test()
    }
  };
  return (
    <div onKeyDown={handleKeyPress}>
      <CustomOffcanvas title="Фильтр" show={showFilter} setShow={setShowFilter}>
        <CustomFilter doFiltrate={test} />
      </CustomOffcanvas>
      <Container>
        <Row>
          <Col md={9} xs={12}>
            <ActorsRowsHeader
              setShowFilter={setShowFilter}
              setSize={setColSize}
            />
            <ActorsInfiniteScroll
              actors={actors}
              fetchMoreData={fetchMoreData}
              colSize={colSize}
              count={count}
            />
          </Col>
          <Col md={3} xs={0} className="d-none d-lg-block">
            <div className="sticky-top" style={{ top: "76px" }}>
              <CustomFilter doFiltrate={test} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default observer(List);
