import { makeAutoObservable } from "mobx"

export default class FilterStore {

    fio = ''
    gender = 0
    minAge = 0
    minHeight = 0
    minWeight = 0
    maxAge = 130
    maxHeight = 300
    maxWeight = 300
    race = 0
    constitution = 0
    eyeColor = 0
    hairColor = 0

    constructor() {
        makeAutoObservable(this)
    }

    clear() {
        this.setFio('')
        this.setGender(0)
        this.setMinAge(0)
        this.setMinHeight(0)
        this.setMinWeight(0)
        this.setMaxAge(130)
        this.setMaxHeight(300)
        this.setMaxWeight(300)
        this.setRace(0)
        this.setConstitution(0)
        this.setEyeColor(0)
        this.setHairColor(0)
    }

    setGender(gender: number) {
        this.gender = gender
    }

    setMinAge(age: number) {
        this.minAge = age
    }

    setMaxAge(age: number) {
        this.maxAge = age
    }

    setMinHeight(height: number) {
        this.minHeight = height
    }

    setMinWeight(weight: number) {
        this.minWeight = weight
    }

    setMaxHeight(height: number) {
        this.maxHeight = height
    }

    setMaxWeight(weight: number) {
        this.maxWeight = weight
    }

    setRace(race: number) {
        this.race = race
    }

    setConstitution(constitution: number) {
        this.constitution = constitution
    }

    setEyeColor(eyeColor: number) {
        this.eyeColor = eyeColor
    }

    setHairColor(hairColor: number) {
        this.hairColor = hairColor
    }

    setFio(fio: string) {
        this.fio = fio
    }

}