import React from "react"
import { Offcanvas } from "react-bootstrap";

interface Props {
    title: string
    show: boolean
    setShow: (value: React.SetStateAction<boolean>) => void
    children: React.ReactElement
}
const CustomOffcanvas = ({ children, title, show, setShow }: Props) => {

    const handleCloseFilter = () => setShow(false)

    return (
        <Offcanvas show={show} onHide={handleCloseFilter} >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{title}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {children}
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default CustomOffcanvas