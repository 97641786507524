
const citizenship = [
    {
        "id": 0,
        "text": "Неизвестно"
    },
    {
        "id": 1,
        "text": "Афганистан"
    },
    {
        "id": 2,
        "text": "Албания"
    },
    {
        "id": 3,
        "text": "Алжир"
    },
    {
        "id": 4,
        "text": "Самоа"
    },
    {
        "id": 5,
        "text": "Андорра"
    },
    {
        "id": 6,
        "text": "Ангола"
    },
    {
        "id": 7,
        "text": "Ангилья"
    },
    {
        "id": 8,
        "text": "Антигуа и Барбуда"
    },
    {
        "id": 9,
        "text": "Аргентина"
    },
    {
        "id": 10,
        "text": "Армения"
    },
    {
        "id": 11,
        "text": "Аруба"
    },
    {
        "id": 12,
        "text": "Австралия"
    },
    {
        "id": 13,
        "text": "Австрия"
    },
    {
        "id": 14,
        "text": "Азербайджан"
    },
    {
        "id": 15,
        "text": " Бахрейн"
    },
    {
        "id": 16,
        "text": "Бангладеш"
    },
    {
        "id": 17,
        "text": "Барбадос"
    },
    {
        "id": 18,
        "text": "Белоруссия"
    },
    {
        "id": 19,
        "text": "Бельгия"
    },
    {
        "id": 20,
        "text": "Россия"
    },
    {
        "id": 21,
        "text": "Бенин"
    },
    {
        "id": 22,
        "text": "Бермуды"
    },
    {
        "id": 23,
        "text": "Бутан"
    },
    {
        "id": 24,
        "text": "Боливия"
    },
    {
        "id": 25,
        "text": "Босния и Герцеговина"
    },
    {
        "id": 26,
        "text": "Ботсвана"
    },
    {
        "id": 27,
        "text": "Бразилия"
    },
    {
        "id": 28,
        "text": "Бруней"
    },
    {
        "id": 29,
        "text": "Болгария"
    },
    {
        "id": 30,
        "text": "Буркина-Фасо"
    },
    {
        "id": 31,
        "text": "Бирма (Мьянма)"
    },
    {
        "id": 32,
        "text": "Бурунди"
    },
    {
        "id": 33,
        "text": "Камбоджа"
    },
    {
        "id": 34,
        "text": "Камерун"
    },
    {
        "id": 35,
        "text": "Канада"
    },
    {
        "id": 36,
        "text": "Канарские острова"
    },
    {
        "id": 37,
        "text": "Кабо-Верде"
    },
    {
        "id": 38,
        "text": "Центральноафриканская Республика"
    },
    {
        "id": 39,
        "text": "Чад"
    },
    {
        "id": 40,
        "text": "Чили"
    },
    {
        "id": 41,
        "text": "Китай"
    },
    {
        "id": 42,
        "text": "Колумбия"
    },
    {
        "id": 43,
        "text": "Коморы"
    },
    {
        "id": 44,
        "text": "Конго"
    },
    {
        "id": 45,
        "text": "Коста-Рика"
    },
    {
        "id": 46,
        "text": "Хорватия"
    },
    {
        "id": 47,
        "text": "Куба"
    },
    {
        "id": 48,
        "text": "Кипр"
    },
    {
        "id": 49,
        "text": "Чехия"
    },
    {
        "id": 50,
        "text": "Дания"
    },
    {
        "id": 51,
        "text": "Джибути"
    },
    {
        "id": 52,
        "text": "Доминика"
    },
    {
        "id": 53,
        "text": "Доминиканская Республика"
    },
    {
        "id": 54,
        "text": "Эквадор"
    },
    {
        "id": 55,
        "text": "Египет"
    },
    {
        "id": 56,
        "text": "Сальвадор"
    },
    {
        "id": 57,
        "text": "Экваториальная Гвинея"
    },
    {
        "id": 58,
        "text": "Эритрея"
    },
    {
        "id": 59,
        "text": "Эстония"
    },
    {
        "id": 60,
        "text": "Эфиопия"
    },
    {
        "id": 61,
        "text": "Фолклендские острова"
    },
    {
        "id": 62,
        "text": "Фиджи"
    },
    {
        "id": 63,
        "text": "Финляндия"
    },
    {
        "id": 64,
        "text": "Франция"
    },
    {
        "id": 65,
        "text": "Французская Гвиана"
    },
    {
        "id": 66,
        "text": "Французская Полинезия"
    },
    {
        "id": 67,
        "text": "Габон"
    },
    {
        "id": 68,
        "text": "Грузия"
    },
    {
        "id": 69,
        "text": "Германия"
    },
    {
        "id": 70,
        "text": "Гана"
    },
    {
        "id": 71,
        "text": "Гибралтар"
    },
    {
        "id": 72,
        "text": "Греция"
    },
    {
        "id": 73,
        "text": "Гренландия"
    },
    {
        "id": 74,
        "text": "Гренада"
    },
    {
        "id": 75,
        "text": "Гваделупа"
    },
    {
        "id": 76,
        "text": "Гуам"
    },
    {
        "id": 77,
        "text": "Гватемала"
    },
    {
        "id": 78,
        "text": "Гвинея"
    },
    {
        "id": 79,
        "text": "Гвинея-Бисау"
    },
    {
        "id": 80,
        "text": "Гайана"
    },
    {
        "id": 81,
        "text": "Гаити"
    },
    {
        "id": 82
    },
    {
        "id": 83,
        "text": "Гондурас"
    },
    {
        "id": 84,
        "text": "Венгрия"
    },
    {
        "id": 85,
        "text": "Исландия"
    },
    {
        "id": 86,
        "text": "Индия"
    },
    {
        "id": 87,
        "text": "Индонезия"
    },
    {
        "id": 88,
        "text": "Иран"
    },
    {
        "id": 89,
        "text": "Ирак"
    },
    {
        "id": 90,
        "text": "Ирландия"
    },
    {
        "id": 91,
        "text": "Израиль"
    },
    {
        "id": 92,
        "text": "Италия"
    },
    {
        "id": 93,
        "text": "Кот-д’Ивуар"
    },
    {
        "id": 94,
        "text": "Ямайка"
    },
    {
        "id": 95,
        "text": "Япония"
    },
    {
        "id": 96,
        "text": "Джонстон (атолл)"
    },
    {
        "id": 97,
        "text": "Иордания"
    },
    {
        "id": 98,
        "text": "Турецкая Республика Северного Кипра"
    },
    {
        "id": 99,
        "text": "Казахстан"
    },
    {
        "id": 100,
        "text": "Кения"
    },
    {
        "id": 101,
        "text": "Французские Южные и Антарктические Территории"
    },
    {
        "id": 102,
        "text": "Кирибати"
    },
    {
        "id": 103,
        "text": "Кувейт"
    },
    {
        "id": 104,
        "text": "Кыргызстан"
    },
    {
        "id": 105,
        "text": "Лаос"
    },
    {
        "id": 106,
        "text": "Латвия"
    },
    {
        "id": 107,
        "text": "Ливан"
    },
    {
        "id": 108,
        "text": "Лесото"
    },
    {
        "id": 109,
        "text": "Либерия"
    },
    {
        "id": 110,
        "text": "Ливия"
    },
    {
        "id": 111,
        "text": "Литва"
    },
    {
        "id": 112,
        "text": "Люксембург"
    },
    {
        "id": 113,
        "text": "Республика Македония"
    },
    {
        "id": 114,
        "text": "Мадагаскар"
    },
    {
        "id": 115,
        "text": "Малави"
    },
    {
        "id": 116,
        "text": "Малайзия"
    },
    {
        "id": 117,
        "text": "Мальдивы"
    },
    {
        "id": 118,
        "text": "Мали"
    },
    {
        "id": 119,
        "text": "Мальта"
    },
    {
        "id": 120,
        "text": "Маршалловы Острова"
    },
    {
        "id": 121,
        "text": "Мартиника"
    },
    {
        "id": 122,
        "text": "Мавритания"
    },
    {
        "id": 123,
        "text": "Маврикий"
    },
    {
        "id": 124,
        "text": "Мексика"
    },
    {
        "id": 125,
        "text": "Федеративные Штаты Микронезии"
    },
    {
        "id": 126,
        "text": "Мидуэй"
    },
    {
        "id": 127,
        "text": "Молдавия"
    },
    {
        "id": 128,
        "text": "Монако"
    },
    {
        "id": 129,
        "text": "Монголия"
    },
    {
        "id": 130,
        "text": "Монтсеррат"
    },
    {
        "id": 131,
        "text": "Марокко"
    },
    {
        "id": 132,
        "text": "Мозамбик"
    },
    {
        "id": 133,
        "text": "Намибия"
    },
    {
        "id": 134,
        "text": "Непал"
    },
    {
        "id": 135,
        "text": "Нидерланды"
    },
    {
        "id": 136,
        "text": "Нидерландские Антильские острова"
    },
    {
        "id": 137,
        "text": "Новая Каледония"
    },
    {
        "id": 138,
        "text": "Новая Зеландия"
    },
    {
        "id": 139,
        "text": "Никарагуа"
    },
    {
        "id": 140,
        "text": "Нигер"
    },
    {
        "id": 141,
        "text": "Нигерия"
    },
    {
        "id": 142,
        "text": "Корейская Народно-Демократическая Республика"
    },
    {
        "id": 143,
        "text": "Северная Ирландия"
    },
    {
        "id": 144,
        "text": "Северные Марианские острова"
    },
    {
        "id": 145,
        "text": "Норвегия"
    },
    {
        "id": 146,
        "text": "Оман"
    },
    {
        "id": 147,
        "text": "Пакистан"
    },
    {
        "id": 148,
        "text": "Палау"
    },
    {
        "id": 149,
        "text": "Пальмира"
    },
    {
        "id": 150,
        "text": "Панама"
    },
    {
        "id": 151,
        "text": "Папуа — Новая Гвинея"
    },
    {
        "id": 152,
        "text": "Парагвай"
    },
    {
        "id": 153,
        "text": "Перу"
    },
    {
        "id": 154,
        "text": "Филиппины"
    },
    {
        "id": 155,
        "text": "Польша"
    },
    {
        "id": 156,
        "text": "Португалия"
    },
    {
        "id": 157,
        "text": "Пуэрто-Рико"
    },
    {
        "id": 158,
        "text": "Катар"
    },
    {
        "id": 159,
        "text": "Реюньон"
    },
    {
        "id": 160,
        "text": "Румыния"
    },
    {
        "id": 161,
        "text": "Российская Федерация"
    },
    {
        "id": 162,
        "text": "Руанда"
    },
    {
        "id": 163,
        "text": "Остров Святой Елены"
    },
    {
        "id": 164,
        "text": "Сент-Винсент и Гренадины"
    },
    {
        "id": 165,
        "text": "Сан-Марино"
    },
    {
        "id": 166,
        "text": "Саудовская Аравия"
    },
    {
        "id": 167
    },
    {
        "id": 168,
        "text": "Сенегал"
    },
    {
        "id": 169,
        "text": "Сейшельские Острова"
    },
    {
        "id": 170,
        "text": "Сьерра-Леоне"
    },
    {
        "id": 171,
        "text": "Сингапур"
    },
    {
        "id": 172,
        "text": "Словакия"
    },
    {
        "id": 173,
        "text": "Словения"
    },
    {
        "id": 174,
        "text": "Соломоновы Острова"
    },
    {
        "id": 175,
        "text": "Сомали"
    },
    {
        "id": 176,
        "text": "Южно-Африканская Республика"
    },
    {
        "id": 177,
        "text": "Южная Георгия и Южные Сандвичевы острова"
    },
    {
        "id": 178,
        "text": "Республика Корея"
    },
    {
        "id": 179,
        "text": "Испания"
    },
    {
        "id": 180,
        "text": "Шри-Ланка"
    },
    {
        "id": 181,
        "text": "Сент-Китс и Невис"
    },
    {
        "id": 182,
        "text": "Сент-Люсия"
    },
    {
        "id": 183,
        "text": "Сен-Пьер и Микелон"
    },
    {
        "id": 184,
        "text": "Судан"
    },
    {
        "id": 185,
        "text": "Суринам"
    },
    {
        "id": 186,
        "text": "Свазиленд"
    },
    {
        "id": 187,
        "text": "Швеция"
    },
    {
        "id": 188,
        "text": "Швейцария"
    },
    {
        "id": 189,
        "text": "Сирия"
    },
    {
        "id": 190,
        "text": "Тайвань"
    },
    {
        "id": 191,
        "text": "Таджикистан"
    },
    {
        "id": 192,
        "text": "Танзания"
    },
    {
        "id": 193,
        "text": "Таиланд"
    },
    {
        "id": 194,
        "text": "Багамские Острова"
    },
    {
        "id": 195,
        "text": "Гамбия"
    },
    {
        "id": 196,
        "text": "Того"
    },
    {
        "id": 197,
        "text": "Тонга"
    },
    {
        "id": 198,
        "text": "Тринидад и Тобаго"
    },
    {
        "id": 199,
        "text": "Тунис"
    },
    {
        "id": 200,
        "text": "Турция"
    },
    {
        "id": 201,
        "text": "Туркмения"
    },
    {
        "id": 202,
        "text": "Теркс и Кайкос"
    },
    {
        "id": 203,
        "text": "Объединённые Арабские Эмираты"
    },
    {
        "id": 204,
        "text": "Великобритания"
    },
    {
        "id": 205,
        "text": "США"
    },
    {
        "id": 206,
        "text": "Уганда"
    },
    {
        "id": 207,
        "text": "Украина"
    },
    {
        "id": 208,
        "text": "Уругвай"
    },
    {
        "id": 209,
        "text": "Узбекистан"
    },
    {
        "id": 210,
        "text": "Вануату"
    },
    {
        "id": 211,
        "text": "Венесуэла"
    },
    {
        "id": 212,
        "text": "Вьетнам"
    },
    {
        "id": 213,
        "text": "Уэйк"
    },
    {
        "id": 214,
        "text": "Уэльс"
    },
    {
        "id": 215,
        "text": "Самоа"
    },
    {
        "id": 216,
        "text": "Йемен"
    },
    {
        "id": 217
    },
    {
        "id": 218,
        "text": "Демократическая Республика Конго"
    },
    {
        "id": 219,
        "text": "Замбия"
    },
    {
        "id": 220,
        "text": "Зимбабве"
    },
    {
        "id": 221
    },
    {
        "id": 222,
        "text": "Лихтенштейн"
    },
    {
        "id": 223,
        "text": "Каймановы острова"
    },
    {
        "id": 224,
        "text": "Остров Рождества"
    },
    {
        "id": 225
    },
    {
        "id": 226,
        "text": "Косово"
    },
    {
        "id": 227,
        "text": "Макао"
    },
    {
        "id": 228,
        "text": "Майотта"
    },
    {
        "id": 229,
        "text": "Черногория"
    },
    {
        "id": 230,
        "text": "Ниуэ"
    },
    {
        "id": 231,
        "text": "Палестина"
    },
    {
        "id": 232,
        "text": "Сен-Мартен"
    },
    {
        "id": 233,
        "text": "Сан-Томе и Принсипи"
    },
    {
        "id": 234,
        "text": "Сербия"
    },
    {
        "id": 235,
        "text": "Шпицберген и Ян-Майен"
    }
] //235

const gender = [
    {
        "id": 0,
        "text": "Неизвестно"
    },
    {
        "id": 1,
        "text": "Мужской"
    },
    {
        "id": 2,
        "text": "Женский"
    },
] //2

const constitution = [
    {
        "id": 0,
        "text": "Неизвестно"
    },
    {
        "id": 1,
        "text": "Атлетичное"
    },
    {
        "id": 2,
        "text": "Бодибилдер"
    },
    {
        "id": 3,
        "text": "Коренастое"
    },
    {
        "id": 4,
        "text": "Полное"
    },
    {
        "id": 5,
        "text": "Среднее"
    },
    {
        "id": 6,
        "text": "Стройное"
    },
    {
        "id": 7,
        "text": "Худощавое"
    }
] //7

const race = [
    {
        "id": 0,
        "text": "Неизвестно"
    },
    {
        "id": 1,
        "text": "Азиатский"
    },
    {
        "id": 2,
        "text": "Арабский"
    },
    {
        "id": 3,
        "text": "Африканский"
    },
    {
        "id": 4,
        "text": "Еврейский"
    },
    {
        "id": 5,
        "text": "Европейский"
    },
    {
        "id": 6,
        "text": "Индийский"
    },
    {
        "id": 7,
        "text": "Кавказский"
    },
    {
        "id": 8,
        "text": "Латино"
    },
    {
        "id": 9,
        "text": "Метис"
    },
    {
        "id": 10,
        "text": "Мулат"
    },
    {
        "id": 11,
        "text": "Славянский"
    }
] //11

const eyeColor = [
    {
        "id": 0,
        "text": "Неизвестно"
    },
    {
        "id": 1,
        "text": "Голубой"
    },
    {
        "id": 2,
        "text": "Зелено-голубой"
    },
    {
        "id": 3,
        "text": "Зеленый"
    },
    {
        "id": 4,
        "text": "Каре-зеленый"
    },
    {
        "id": 5,
        "text": "Каре-серый"
    },
    {
        "id": 6,
        "text": "Кариее"
    },
    {
        "id": 7,
        "text": "Карий"
    },
    {
        "id": 8,
        "text": "Серо-голубой"
    },
    {
        "id": 9,
        "text": "Серо-зеленый"
    },
    {
        "id": 10,
        "text": "Серо-синий"
    },
    {
        "id": 11,
        "text": "Серый"
    },
    {
        "id": 12,
        "text": "Сине-зеленый"
    },
    {
        "id": 13,
        "text": "Синий"
    },
    {
        "id": 14,
        "text": "Черный"
    }
]//14

const hairColor = [
    {
        "id": 0,
        "text": "Неизвестно"
    },
    {
        "id": 1,
        "text": "Блондин"
    },
    {
        "id": 2,
        "text": "Брюнет"
    },
    {
        "id": 3,
        "text": "Отсутствуют"
    },
    {
        "id": 4,
        "text": "Русый"
    },
    {
        "id": 5,
        "text": "Рыжий"
    },
    {
        "id": 6,
        "text": "Седой"
    },
    {
        "id": 7,
        "text": "Шатен"
    }
]//7

const education = [
    {
        "id": 999,
        "text": "Неизвестно"
    },
    {
        "id": 666,
        "text": "Без высшего актёрского образования"
    },
    {
        "id": 0,
        "text": "Высшее актёрское образование"
    },
    {
        "id": 1,
        "text": "ГИТИС"
    },
    {
        "id": 2,
        "text": "РГИСИ (бывш. СПбГАТИ, ЛГИТМиК)"
    },
    {
        "id": 3,
        "text": "ТИ им. Б. Щукина"
    },
    {
        "id": 4,
        "text": "ВТУ им. М.С. Щепкина"
    },
    {
        "id": 5,
        "text": "ВГИК"
    },
    {
        "id": 6,
        "text": "Школа-студия МХАТ"
    },
    {
        "id": 7,
        "text": "ЯГТИ"
    },
    {
        "id": 8,
        "text": "ВШДСИ «Школа Г.Г. Дадамяна»"
    },
    {
        "id": 9,
        "text": "МГИК"
    },
    {
        "id": 10,
        "text": "КНУТКиТ (КГИТИ) им. И.К. Карпенко-Карого"
    },
    {
        "id": 11,
        "text": "ЕГТИ"
    },
    {
        "id": 12,
        "text": "ИСИ"
    },
    {
        "id": 13,
        "text": "БГАИ"
    },
    {
        "id": 14,
        "text": "Школа драмы Германа Сидакова"
    },
    {
        "id": 15,
        "text": "СПбГИКиТ"
    },
    {
        "id": 16,
        "text": "МСИ им. Г.Р. Державина"
    },
    {
        "id": 17,
        "text": "СПбГИК"
    },
    {
        "id": 18,
        "text": "Институт театрального искусства (ИТИ)"
    },
    {
        "id": 19,
        "text": "ВГИИ"
    },
    {
        "id": 20,
        "text": "НТУ им. Е.А. Евстигнеева"
    }
]//22



const media = [
    {
        "id": 0,
        "text": "Другое",
        "icon": "website"
    },

    {
        "id": 1,
        "text": "Номер телефона",
        "icon": "tel"
    },
    {
        "id": 2,
        "text": "Email",
        "icon": "email"
    },
    {
        "id": 3,
        "text": "Facebook",
        "icon": "fb"
    },
    {
        "id": 4,
        "text": "Instagram",
        "icon": "inst"
    },
    {
        "id": 5,
        "text": "YouTube",
        "icon": "yt"
    },
    {
        "id": 6,
        "text": "Vk",
        "icon": "vk"
    },
    {
        "id": 7,
        "text": "КиноПоиск",
        "icon": "kp"
    },
    {
        "id": 8,
        "text": "Кинотеатр",
        "icon": "kt"
    },
    {
        "id": 9,
        "text": "Сайт",
        "icon": "website"
    }
]//9
export enum MediaType {
    other,
    tel,
    email,
    fb,
    inst,
    youtube,
    vk,
    kp,
    kl,
    kt,
    website
}
export interface Media {
    type: MediaType,
    src: string
}
const roles = [
    { "id": 0, "text": 'admin' },
    { "id": 1, "text": 'moderator' }
]
export interface commonStructure {
    id: number,
    text?: string
    icon?: string
}

const toMapStructure = (toMapObject: commonStructure[]): Map<number, string> => {
    const maped = new Map<number, string>()
    toMapObject.map(({ id, text = '' }) => {
        maped.set(id, text)
    })
    return maped
}
const toMapStructureIcon = (toMapObject: commonStructure[]): Map<number, string> => {
    const maped = new Map<number, string>()
    toMapObject.map(({ id, icon = 'website' }) => {
        maped.set(id, icon)
    })
    return maped
}
const agesWord = [
    'год',
    'года',
    'лет',
]

function declOfNum(n?: number) {
    if (n) return agesWord[n % 10 == 1 && n % 100 != 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
}

const mapedTypes = [
    toMapStructure(citizenship),
    toMapStructure(gender),
    toMapStructure(constitution),
    toMapStructure(race),
    toMapStructure(eyeColor),
    toMapStructure(hairColor),
    toMapStructure(education),
    toMapStructureIcon(media),
    toMapStructure(roles)
]


export enum typeToGetMapedData {
    citizenship,
    gender,
    constitution,
    race,
    eyeColor,
    hairColor,
    education,
    media,
    roles
}
function getMapedData(type: typeToGetMapedData, id?: number) {
    if (id !== null && id !== undefined) {
        return mapedTypes[type].get(id)
    }
    return 'Неизвестно'
}



export interface Film {
    name: string
    date?: string
    character?: string
}
export {
    citizenship,
    gender,
    constitution,
    race,
    eyeColor,
    hairColor,
    education,
    media,
    roles,
    declOfNum,
    toMapStructure,
    getMapedData
}