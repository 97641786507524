import React, { useState } from "react"
import {  Card, Col} from 'react-bootstrap';
import { declOfNum } from "../../../helpers/types"
import { IActor } from '../../../models/IActor'
import { AiOutlineCamera, AiOutlineVideoCamera } from 'react-icons/ai'
import { observer } from "mobx-react-lite"
import '../custom.css'
import Actor from "../Actor"
import ModalActor from "./ModalActor";

interface Props {
    actor: IActor
}
const ActorSimpleRow = ({ actor }: Props) => {

    const [isShown, setIsShown] = useState(false)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const getFullName = (firstname?: string, lastname?: string, patronymic?: string) => {
        return [firstname, lastname, patronymic].join(' ')
    }

    return (
        <>
            <ModalActor
                title={getFullName(actor.firstName, actor.lastName)}
                id={actor._id}
                show={show}
                handleClose={handleClose}
            >
                <Actor id={actor._id} socialMediaColSize={4} avatarHeight={'515px'} />
            </ModalActor>

            <Col md={3} xs={12}
                style={{ cursor: 'pointer' }}
                onClick={handleShow}
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}
            >
                <Card style={{ boxShadow: isShown ? '0px 5px 10px 2px rgba(34, 60, 80, 0.2)' : 'none' }}>
                    <div style={{ position: 'relative' }}>
                        <div style={{
                            backgroundColor: 'aliceblue',
                            backgroundImage: actor.avatar ? `url(${actor.avatar})` : 'none',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            width: '100%',
                            height: '256px'
                        }} />
                        <div hidden={!isShown} style={{
                            position: 'absolute',
                            bottom: 0,
                            left: '20%',
                            width: 'auto',
                            paddingLeft: '5px',
                            paddingRight: '5px',
                            height: '30px',
                            textAlign: 'center',
                            backgroundColor: "#352f2f63"
                        }}>
                            <div style={{ color: 'white' }}>
                                <AiOutlineCamera width={20} height={20} color="white" />
                                <span style={{ fontSize: '12px' }}> {actor.photos?.length} </span>
                            </div>
                        </div>
                        <div hidden={!isShown} style={{
                            position: 'absolute',
                            bottom: 0,
                            right: '20%',
                            width: 'auto',
                            paddingLeft: '5px',
                            paddingRight: '5px',
                            height: '30px',
                            textAlign: 'center',
                            backgroundColor: "#352f2f63"
                        }}>
                            <div style={{ color: 'white' }}>
                                <AiOutlineVideoCamera width={20} height={20} color="white" />
                                <span style={{ fontSize: '12px' }}> {actor.videos?.length} </span>
                            </div>
                        </div>

                    </div>

                    <Card.Body style={{ textAlign: 'center' }}>
                        <Card.Title>{actor.lastName} <br />{actor.firstName}</Card.Title>
                        <Card.Text style={{ textDecoration: 'none', color: '#9FAAB2' }} >
                            {actor.age} {declOfNum(actor.age ?? 1)}
                        </Card.Text>
                    </Card.Body>
                </Card>
                <br />
            </Col >
        </>
    )
}

export default observer(ActorSimpleRow)