import ActorStore from "./ActorStore"
import FilterStore from "./FilterStore"
import VisitorStore from "./VisitorStore"

const actorStore = new ActorStore()
const filterStore = new FilterStore()
const visitorStore = new VisitorStore()

export interface State {
    actorStore: ActorStore,
    filterStore: FilterStore,
    visitorStore: VisitorStore,
}


export {
    actorStore,
    visitorStore,
    filterStore,
}