import React from "react"
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";


const NavbarComponent = () => {

    return (
        <>
            <Navbar bg="light" expand="lg" sticky="top" >
                <Container>
                    <Navbar.Brand as={Link} to="/" style={{ color: '#2f60bd', marginLeft: 12 }}>КИНОСТАРТ</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" style={{ marginLeft: 15 }}>
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/actors">Актеры</Nav.Link>
                            <Nav.Link as={Link} to="/collections">Моя коллекция</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default NavbarComponent