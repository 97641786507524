import React from 'react'
import CustomArrowsControl from './CustomArrowsControl';

interface HeaderProps {
    label: string
    items: string[]
    type: TypeAddForm
}

enum TypeAddForm {
    photo,
    video
}
const CustomSliderHeader = ({ label, items, type }: HeaderProps) => {
    const moveLeft = () => {
        items.push(items.shift() as string)
    }

    const moveRight = () => {
        items.unshift(items.pop() as string)
    }


    const isHidden = (type: TypeAddForm) => {
        if (type === TypeAddForm.photo) {
            return 1
        }
        if (type === TypeAddForm.video) {
            return 1
        }
        return 1
    }

    return (
        <div className="d-flex justify-content-between">
            <div style={{ marginLeft: 10 }} className="d-flex justify-content-start">
                <h5>
                    <span style={{ color: '#2f60bd', fontWeight: 300 }}>{label} <small>({items.length})</small></span>
                </h5>
            </div>
            <CustomArrowsControl leftClick={moveLeft} rightClick={moveRight} isHidden={items.length > isHidden(type) ? true : false} />
        </div>
    )
}

export default CustomSliderHeader