import '../custom.css'
import React, { useCallback, useState } from "react"
import { Row } from 'react-bootstrap'

import CustomSliderHeader from '../CustomSliderHeader'
import CustomSliderPhotoItem from './CustomSliderPhotoItem'
import ImageViewer from "react-simple-image-viewer"

import { observer } from "mobx-react-lite"

interface Props {
    items: string[]
}

enum TypeAddForm {
    photo,
    video
}

const CustomPhotoSlider = ({ items = [] }: Props) => {

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    return (
        <div>

            {isViewerOpen && (
                <ImageViewer
                    src={items}
                    currentIndex={currentImage}
                    onClose={closeImageViewer}
                    disableScroll={true}
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)",
                        zIndex: 10001,
                    }}
                    closeOnClickOutside={true}
                />
            )}
            <div style={{ marginLeft: -10, marginRight: 10, userSelect: "none" }} >
                <CustomSliderHeader label="Фото" items={items} type={TypeAddForm.photo} />
                <Row>
                    {items.map((photo, id) => <CustomSliderPhotoItem onClick={() => openImageViewer(id)} key={id} id={id} photo={photo} />)}
                </Row>
            </div>
        </div>
    )
}

export default observer(CustomPhotoSlider)